.campaign-block-list {
  @include screen-md {
    display: flex;
  }

  & .campaign-block:not(:last-child) {
    @include screen-md-max {
      margin-bottom: 32px;
    }
  }

  & .campaign-block:not(:nth-child(1)) {
    @include screen-md {
      margin-left: $spacing-sm;
    }
  }
}
