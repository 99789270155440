.grouped-search-page {
  &--title {
    @include list-page-title;
  }

  &--results-description {
    @include font-tiny;
    margin-bottom: $spacing3;
  }
}
