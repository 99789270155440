.link-with-text {
  padding: $spacing4 $spacing3;

  &.-theme-gray {
    background: $background-grey2;
  }

  &.-theme-prominent {
    background: $background-light-blue;
  }

  h3 {
    margin: 0;
    font-size: 0; // Prevents additional spacing

    .link {
      @include font-headline3;
    }
  }

  &--text {
    // Values to align text with heading
    $iconSizeMobile: calc(24px + #{$spacing3});
    $iconSize: calc(32px + 21px);

    @include font-small;
    margin: $spacing2 0 0;
    padding-left: $iconSizeMobile;

    @include screen-lg {
      padding-left: $iconSize;
    }
  }
}