.table-block {
  .table {
    margin-bottom: $spacing4;
    @include screen-md {
      margin-bottom: 60px;
    }

    &--title {
      @include fluid-type(24px, 30px);
      margin-bottom: $spacing6;
    }
  }

  &.-wide {
    @include screen-lg {
      $defaultWidth: 840px;
      $newWidth: 1024px;
      $expandSideBy: ($newWidth - $defaultWidth) / 2;
      margin-left: -$expandSideBy;
      margin-right: -$expandSideBy;
    }
  }
}
