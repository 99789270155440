.fluid-image {
  @media screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.-theme-rounded {
      border-radius: $radius3;
    }

    &.-theme-rounded-small {
      border-radius: $radius2;
    }


    &--image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center;
    }

    >img {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  //ALWAYS explicit print handling for images - some attributes may not be supported by evoPdf.
  //test thoroughly
  @media print {

    &.-theme-anchored {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      >img {
        opacity: 1;
        width: 100%;
        height: 100%;
      }
    }

    &.-theme-mosaic {
      >img {
        aspect-ratio: 3 / 2;
        opacity: 1;
        height: 12em;
      }
    }

  }
}