.link {
  text-decoration: none;

  &:link,
  &:visited {
    color: $link-color;

    &.-is-external-link {
      @include external-link-style;

      .external-link-icon-skinny {
        width: unset;
        height: unset;
      }
    }
  }

  //text-decorations cannot be overriden, so underline-effect exists within optional class
  &.-has-hover-effect:link:hover,
  &.-has-hover-effect:visited:hover {
    color: $text-color;
    text-decoration: underline;
  }

  &.-has-icon-left,
  &.-has-icon-right {
    display: inline-flex;
    align-items: center;
  }

  &.-has-children.-has-icon-left {
    svg {
      margin-right: $spacing-sm;
    }
  }

  &.-has-children.-has-icon-right {
    svg {
      margin-left: $spacing-sm;
    }
  }

  &.-theme-action-link {
    .link--text {
      @include underline-animation('span');
    }

    display: flex;
    width: fit-content;
    color: $primary-grey3 !important;
    font-size: convert-to-rem(18px);

    .action-link-icon {
      $iconSize: 24px;
      position: relative;
      width: $iconSize;
      min-width: $iconSize;
      height: $iconSize;
      margin-right: $spacing2 !important;

      .fluid-image--image {
        transition: background-position $transition;
      }
    }

    &.-theme-large {
      @include screen-lg {
        .action-link-icon {
          width: 32px;
          height: 32px;
          margin-right: 21px !important;
        }
      }
    }

    &.-has-hover-effect:hover {
      text-decoration: none !important;

      .link--text {
        &>*::after {
          transform: scaleX(1);
        }
      }

      .fluid-image--image {
        background-position: 24px 0;
      }

      &.-theme-large {
        @include screen-lg {
          .fluid-image--image {
            background-position: 32px 0;
          }
        }
      }
    }
  }

  &.-theme-large {
    font-size: convert-to-rem(24px);
  }

  &.-theme-underline {
    text-decoration: underline;
  }

  &.-theme-blue {
    color: $primary-blue;
  }

  &.-theme-black {
    color: $text-color;
  }

  &.-theme-bold {
    font-weight: bold;
  }

  &.-theme-white {
    color: white !important;
  }

  &.-theme-full-width {
    display: block;
  }

  &.-theme-orange-tag {
    border-color: $focus-color !important;
    color: $background-color !important;

    &:hover {
      background-color: $focus-color !important;
      border-color: $focus-color !important;
      text-decoration: none !important;
    }
  }

  &.-theme-orange-icon {
    svg {
      color: $focus-color;
    }
  }

  &.-theme-inline-icon {
    display: inline-block;

    svg {
      display: inline-block;
    }
  }

  &.-theme-small {
    @include font-size-sm;
  }

  &.-theme-tag {
    @include font-data;
    display: inline-block;
    background-color: $tags-background;
    border-radius: 30px;
    padding: 6px 8px;
    letter-spacing: .02em;
    color: $primary-grey3;
    line-height: 1;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: .02em;
    line-height: 1;
    margin-right: 8px;
    margin-top: 8px;
    text-transform: uppercase;

    &:link:hover,
    &:visited:hover {
      text-decoration: none;
      background-color: $primary-blue;
      color: white;
    }
  }

  &.-theme-portfolio {
    color: black;
    font-size: 1.4rem;
    display: flex;
    word-break: break-word;
    align-items: baseline;
    line-height: 1.2;

    .link-arrow {
      flex: none;
      height: 1rem;
      width: 1rem;
    }
  }

  &.wrapped-heading-for-print {
    @media print {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      word-break: break-word;
      font-size: 1.25rem;
    }
  }

  &--text {
    flex: 1;
  }

  &-line {
    display: block;
  }
}