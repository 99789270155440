.tabs-mobile {
  position: relative;

  &--label {
    @include font-data;
    display: block;
    margin-bottom: $spacing1;
  }

  &--dropdown-button {
    @include font-body-subtitle;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    margin-bottom: $spacing-lg;
    padding: $spacing-xs $spacing-sm $spacing-xs $spacing-md;
    line-height: 145%;
    border-radius: $radius1;
    border: 1px solid $primary-grey2;
    background: none;
  }

  &--collapse {
    position: absolute;
    z-index: 60;
    width: 100%;
    top: 0;
  }

  &--dropdown {
    padding: 30px;
    border-radius: 8px;
    border: 1px solid $primary-grey2;
    background: white;

    &-title {
      @include font-data;
      border-bottom: 1px solid $divider-grey1;
      padding: 0 0 20px 0;
      margin: 0;
    }

    &-list {
      @include remove-list-styles;
      margin: 0;
    }

    &-item {
      border-bottom: 1px solid $divider-grey1;
      padding: $spacing2 0;
    }
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: 50;
  }
}
