.message-global-modal {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 5%);
  background-color: $info-yellow;
  width: 100%;
  padding: 0;
  z-index: 102;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @include screen-md {
    min-width: 763px;
    width: 830px;
    max-width: 100%;
    padding: $spacing-lg;
    padding-top: $spacing-md;
  }

  &.-theme-red {
    background-color: $info-red;
  }

  &.-theme-blue {
    background-color: $info-blue;
    color: black;
  }

  &--button {
    margin-left: auto;
    padding: $spacing-sm;
    padding-bottom: 0;
    box-sizing: content-box;
  }

  &--message {
    padding-top: 0 !important;

    .message--content {
      align-items: flex-start;
      margin-right: $spacing-lg;

      .icon {
        margin-right: $spacing-lg;
      }
    }
  }
}
