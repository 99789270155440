.breadcrumbs {
  @include font-caption;
  font-family: 'IBM';
  text-transform: uppercase;
  padding: $spacing-sm 0;
  background: white;

  @media print {
    display: none;
  }

  ul {
    @include remove-list-styles;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: $spacing-md;
    overflow: hidden;

    @include screen-md {
      display: flex;
      padding: $spacing1;
    }
  }

  li {
    margin-top: $spacing-sm;
    margin-right: 10px;
    padding-right: 10px;

    @include screen-md {
      @include line-separator;
    }

    &.-right-most {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 0px;
    }

    &.current-page {
      color: $primary-grey2;
    }
  }

  svg {
    margin-top: 2px;
  }

  &--header {
    @include screen-md {
      display: none;
    }
  }

  :root &--toggle {
    @include font-caption;
    font-family: 'IBM';
    text-transform: uppercase;
    width: 100%;
    color: $primary-grey3;
    text-align: left;
    padding: unset;

    &.adjust-width {
      width: calc(100% - 13em);
    }

    svg {
      transform: rotate(0);
      transition: transform 0.2s;
      display: inline-block;
      margin-right: $spacing-sm;
      margin-top: 0;
    }

    &[aria-expanded='true'] svg {
      transform: rotate(180deg);
    }
  }

  &--collapse {
    @include screen-md-max {
      margin-left: 25px;
    }

    @include screen-md {
      height: auto !important;
      visibility: visible !important;
    }
  }

  &--root {
    color: $primary-grey3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
