.portfolio-content {
  @include screen-md {
    display: flex;
  }

  &.-inside-priority {
    .theme-portfolio {
      font-size: convert-to-rem(20px);
    }

    .link-with-text {
      .link {
        font-size: convert-to-rem(20px);
      }

      &--text {
        font-size: 18px;
        margin-left: calc(1rem + 8px);
      }
    }

    .contact-list-item--title {
      font-size: convert-to-rem(16px);
    }

    .nested-link--title {
      font-size: convert-to-rem(16px);
    }

    .link-list {
      .link {
        font-size: convert-to-rem(20px);
      }
    }

    .portfolio-content--blocks {
      margin-top: 0;
    }
  }

  &--heading {
    @include underline;

    &:before {
      width: 100%;
    }

    &.-with-section-title {
      @include has-section-title;

      .section--title-links {
        display: flex;

        @include screen-md {
          flex-direction: row;

          a.link {
            &:not(:last-of-type) {
              padding: 0px 48px 0px 0px;
            }

            display: flex;
            align-items: center;
          }
        }

        @include screen-md-max {
          flex-direction: column;

          a.link {
            &:not(:first-of-type) {
              padding: 5px 0px;
            }

            width: max-content;
            display: flex;
          }
        }
      }
    }
  }

  &--title.heading {
    @include fluid-type(20px, 24px);
    font-weight: 500;
    padding-bottom: $spacing-md;

    @include screen-md-max {
      margin-top: 0;
    }
  }

  &--text {
    margin-top: -1rem;
    padding-bottom: 0.5rem;
  }

  &--blocks {
    margin-top: 1rem;

    @include screen-md {
      margin-top: 2rem;
    }

    &.-top-content-is-text {
      margin-top: calc(2rem - 3px);

      @include screen-md {
        margin-top: calc(2rem - 5px);
      }
    }
  }

  &--content {
    width: 100%;
  }

  &--icon {
    height: 32px;
    width: 32px;
    margin-bottom: $spacing-sm;

    >* {
      stroke: $primary-blue;
    }

    @include screen-lg {
      margin: 1.563rem 1.25rem 0 0;
    }

    @include screen-lg-max {
      margin: 1.3rem 1.25rem 0 0;
    }
  }
}