.tibi-proposal-page {
  margin-top: $spacing-xl;

  &--heading {
    margin-bottom: $spacing5;
    margin-top: $spacing2;
  }

  &--preamble {
    font-size: 1.5rem;
    margin-bottom: $spacing-xl;
    max-width: 888px;
  }

  &--contact {
    > span {
      @include font-body;

      &:after {
        content: ':';
        margin-right: $spacing-sm;
      }
    }
  }

  &--share {
    @include font-size-sm;
    border-top: 1px solid $element-color--light;
    margin: $spacing-xl 0 0;
    padding-top: $spacing-lg;
  }

  &--header {
    padding: $spacing-lg 0 $spacing-lg;

    &-preamble {
      margin-bottom: $spacing-xl;
    }

    &-actions {
      display: flex;
      margin-top: $spacing-lg;

      .button {
        height: 40px;
      }
    }
  }

  &--apply-button {
    margin-right: 1rem;
  }

  &--download {
    .options-modal__button {
      @extend .-theme-secondary;
    }

    @include screen-sm-max {
      .options-modal__button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &--top-content-area {
    margin-top: $spacing10;
  }

  &__header-container {
    background-color: #f4f4f4;
    padding: $spacing-lg;
  }

  &__back-button {
    display: inline-flex;
    margin-bottom: $spacing-xl;
    gap: $spacing-sm;

    svg {
      rotate: 90deg;
    }
  }

  &__step-tag-container {
    margin-bottom: 24px;
  }

  .message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: $spacing-md;

    &--content {
      max-width: initial;
    }
  }
}
