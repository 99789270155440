.newsletter-admin-page {
  &--input-fields {
    margin: $spacing-xl 0;
  }

  &--button-wrapper {
    margin-bottom: $spacing-xxxl;
  }

  &--unsubscribe-link {
    display: block;
    margin: $spacing-lg 0;

    @include screen-md {
      display: inline-block;
      margin: 0 $spacing-xl;
    }
  }
}
