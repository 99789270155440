.carousel-items-wrapper {
  overflow: hidden;
  position: relative;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    transition: transform 0.5s $ease-in-out;
    will-change: transform;

    > li {
      flex-shrink: 0;
    }
  }
}
