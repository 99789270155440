.office-block {
  @include font-body;

  h2 {
    @include font-headline2;
    margin: 0;
  }

  &--info {
    display: flex;
    flex-wrap: wrap;
  }

  &--info-item {
    width: 100%;
    margin-top: $spacing4;

    @include screen-md {
      width: 50%;
      padding-right: $spacing4;
    }

    @include screen-lg {
      width: 25%;
    }
  }
}
