.number-list-block {
    display: grid;

    &.-theme-two-columns {
        grid-template-columns: repeat(auto-fill, minmax(300px, 50%));

        @include screen-md-max {
            grid-template-columns: 1fr;
        }
    }

    &.-theme-three-columns {
        grid-template-columns: repeat(auto-fill, minmax(300px, 33.3%));

        @include screen-md-max {
            grid-template-columns: 1fr;
        }
    }


}