.schedule {
  &--item {
    align-items: baseline;
    padding: $spacing-sm $spacing-md;
    margin: $spacing-md 0;

    @include screen-sm {
      display: flex;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--break {
      background: $element-color--lightest;
    }

    &-time {
      font-size: 1.4rem;
      margin: 0 $spacing-lg $spacing-sm 0;

      @include screen-sm {
        margin-left: 0;
      }
    }

    &-title {
      margin: 0 0 $spacing-xs;
    }
    &-content {
      & blockquote {
        margin: $spacing-md 0;
      }
    }
  }
}
