.table-mobile-item {
  margin-bottom: 12px;

  &--header {
    @include font-body;
    background: $background-grey2;
    border: none;
    width: 100%;
    text-align: left;
    padding: $spacing-md;
  }

  & &--title {
    @include font-headline3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: $spacing1;
  }

  &--icon {
    margin-top: 3px;
    min-width: 20px;
    transition: transform $transition;

    &.-opened {
      transform: rotate(180deg);
    }
  }

  &--list {
    // Using "grid" to make all "labels" equal to the width of the longest "label"
    $gap: 2rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: $gap;
    padding: $spacing4 0;

    &-label {
      @include font-body-subtitle;
    }

    &-value {
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        border-bottom: 1px solid $divider-grey1;
        padding-top: $gap / 2;
      }
    }
  }
}
