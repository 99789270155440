.youtube-video {
  position: relative;
  background-color: black;

  &--iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    padding-top: (9 / 16) * 100%;
  }
}
