.header {
  position: relative;
  z-index: 2;
  background-color: white;
  padding: $spacing4 0;
  color: $text-color;
  transition: background-color 0.5s, color 0.5s;

  @media print {
    display: none;
  }

  @include screen-sm {
    padding: $spacing5 0;
  }

  &--logo {
    display: block;

    img {
      display: block;
      max-width: 170px;
      max-height: 60px;

      @include screen-sm {
        max-width: 204px;
        max-height: 100px;
      }

      // Some svg files have zero height and width natively.
      &[src*='.svg'] {
        width: 100%;
      }
    }

    span {
      @include visually-hidden;
    }
  }

  &--content {
    display: flex;
    align-items: center;
  }

  &--nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
    transition: transform 0.5s $ease-out;

    // This might look redundant, but it keeps IE11 from doing a really weird jump when the '-menu-is-open' class is removed.
    transform: translateX(0);

    // The hard coded number is the approximate width of the 'MENU' button. I know...
    $magic-number: $menu-width-lg - 130px;

    &.-menu-is-open {
      @include screen-lg {
        transform: translateX(-#{$magic-number}) translateX(5vw);
      }

      @media (min-width: 1500px) {
        transform: translateX(50vw)
          translateX((-$spacing-sm - $magic-number - ($content-width-xl / 2)));
      }

      @media (min-width: ($content-width-xl + (2 * $menu-width-lg))) {
        transform: none;
      }
    }
  }

  &--link {
    padding-left: $spacing5;
  }

  &--search {
    padding-left: $spacing7;
  }

  :root &--link .link {
    color: inherit;
  }

  &--search,
  &--menu {
    @include font-size-body;
  }

  :root &--menu {
    padding-left: $spacing2;
  }

  &--menu {
    &-button {
      .icon {
        @include screen-md {
          margin-left: $spacing-md !important;
          width: 26px;
          height: 22px;
        }
      }
    }
  }

  &--link:not(.-show-on-mobile),
  &--search {
    @include screen-lg-max {
      display: none !important;
    }
  }
}
