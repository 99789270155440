.info-block {
  height: 100%;
  font-size: convert-to-rem(16px);
  padding: $box-padding;
  background: $background-grey2;

  &--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing3;
    padding-bottom: 12px;
    border-bottom: 1px solid $divider-grey2;

    &-title {
      margin: 0 $spacing3 0 0;
      font-size: convert-to-rem(24px);
      font-weight: 500;
      line-height: 125%;

      .link--text {
        .hyphenate::after {
          transform: scaleX(1) !important;
        }
      }

      @media print {
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
        font-size: 1.25rem;
      }
    }
  }

  &--text {
    margin-bottom: $spacing4;
  }

  &--link-list {
    & > li {
      padding: 5px 0 !important;
    }
  }

  &--cta-button {
    margin-top: 1rem;
  }

  &--icon-image {
    margin-bottom: $spacing-md;
    width: $spacing5;
    height: $spacing5;
  }

  @media print {
    word-break: break-word;
  }
}
