.menu {
  position: relative;
  height: 100%;
  background-color: $primary-grey3;
  color: white;
  padding: $spacing5 $spacing4;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.5s $ease-out, visibility 0s 0.5s;
  box-shadow: -2px 0 8px rgba(black, 0.2);

  @include screen-md {
    padding: $spacing4 $spacing4; // Hard coded value to please the designer 🤷‍
  }

  &.-is-open {
    transition-delay: 0s;
    transform: none;
    visibility: visible;
  }

  a {
    text-decoration: none;
  }

  &--wrapper {
    margin-left: auto;
    width: 90%;
    max-width: 400px;
    height: 100%;

    @include screen-lg {
      max-width: none;
      width: $menu-width-lg;
    }
  }

  &--content {
  }

  &--header {
    display: flex;
    justify-content: flex-end;

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &--close {
    color: white;
    text-transform: uppercase;
    height: 2rem;
    padding: 0;
  }

  &--links {
    margin-left: auto;

    ul {
      @include remove-list-styles;
      display: flex;
      flex-direction: column;
      margin: $spacing-sm 0 0;
    }

    li {
      padding: 0;
      border: none;
      padding-left: $spacing-md;
      text-align: right;
      margin: $spacing-xs 0;

      a:not(.-has-icon-right) {
        margin-right: 12px;
      }
    }

    @include screen-lg {
      display: none;
    }
  }

  &--header-link-list {
    @include screen-lg {
      display: none;
    }
  }
}
