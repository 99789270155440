.page-header {
  $self: &;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: $spacing6;

  @include screen-lg {
    flex-direction: row;
    margin-bottom: $spacing8;
    max-height: 474px;

    @media print {
      max-height: none;
    }
  }

  &--title-only {
    max-width: $content-width-xl;
    margin: 0 $spacing-lg;
    flex-direction: column;

    #{$self}--title {
      @include font-display2;
      margin: $spacing5 0 $spacing-xl;

      @include screen-md {
        margin: $spacing-xxl 0;
      }

      @include screen-lg {
        width: 50%;
        margin-right: $spacing5;
      }
    }

    @include screen-md {
      margin: 0 32px;
    }

    @include screen-lg {
      @include explicit-margin-until-max-width($content-width-xl, $spacing-xl);
      flex-direction: row;
    }
  }

  &--content {
    flex: 1;
    padding: $spacing5 $spacing7 $spacing5;
    padding-left: 0px; // remove left padding to align with logo

    @include screen-lg-max {
      padding: $spacing-md;

      #{$self}--title-only & {
        padding: 0 0 $spacing8 0;
      }
    }

    @include screen-lg {
      display: flex;
      flex-direction: column;
      justify-content: start;
      max-width: calc(min(670px, 46vw)); //align with logo
      width: 50%;
      margin-left: auto;

      #{$self}--title-only & {
        padding: $spacing9 0px $spacing9 0px;
      }
    }
  }

  &--image-or-map {
    position: relative;
    aspect-ratio: 4 / 3;
    margin-left: $spacing3;
    max-height: -webkit-fill-available;

    @include screen-lg {
      width: 50%;
      height: auto;
      margin-left: 0;
    }

    @include screen-lg-max {
      height: 235px;
    }
  }

  &--title {
    @include font-headline1;
    margin-top: $spacing3;
    margin-bottom: $spacing5;
  }

  &--links {
    margin-bottom: $spacing3;
    display: flex;
    flex-direction: column;

    @include screen-sm {
      display: block;
    }
  }

  &--link {
    margin: $spacing-md $spacing-md 0 0;
  }

  &--ingress {
    @include font-lead;
    margin-top: 0;
    margin-bottom: $spacing3;

    @include screen-md {
      margin-bottom: $spacing5;
    }
  }


}