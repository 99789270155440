.vacancy {
  padding: $spacing5 0;
  border-bottom: 1px solid $background-grey3;

  @include screen-md {
    display: flex;
  }

  &--left {
    @include screen-md-max {
      margin-bottom: 32px;
    }
  }

  &--date {
    width: 200px;
    height: max-content;
    margin-right: $spacing5;

    &.-is-canceled,
    &.-is-completed,
    &.-result-is-published {
      color: $text-grey;
    }

    &.-is-canceled {
      .vacancy--date-date {
        text-decoration: line-through;
      }
    }

    &.-is-planned {
      padding-left: 1rem;
      border-left: 2px dashed black;
    }

    &-label {
      @include font-data;
      margin-top: 2px; // Align with other column
    }

    &-date {
      color: $primary-blue;
      margin-top: 6px;
      font-size: convert-to-rem(24px);
      font-weight: 700;
    }
  }

  &--globe-icon {
    margin-top: $spacing5;
    color: $text-color;
  }

  &--link {
    text-decoration: none;
    color: inherit;

    span {
      border-bottom: 1px solid $primary-blue;
    }

    @include screen-sm {
      @include underline-animation('span');

      span {
        border-bottom: none;
      }
    }
  }

  &--title {
    margin-top: 6px;
    margin-bottom: $spacing3;
    font-size: convert-to-rem(24px);
    font-weight: 500;
    line-height: 125%;

    @include screen-md {
      margin-bottom: $spacing4;
    }
  }

  &--text {
    margin: $spacing5 0px 0px;
  }

  &--right {
    flex: 1;

    .status {
      margin-top: 0;
    }
  }
}
