.input-email {
  &.-invalid {
    input {
      border: 2px solid $error-red !important;
      box-shadow: unset !important;
      background: $background-grey2;
    }
    .icon {
      color: $text-color;
    }
  }
  & input {
    width: 20rem;
    max-width: 100%;
  }

  &--error {
    font-size: 14px;
    font-weight: 400;
    margin-top: $spacing1;
    color: $error-red;
  }
}
