@import 'color-shared.scss';
@import 'mixins.scss';
@import 'vars.scss';

/* Floating Action Button (FAB) style */
#openBtn {
    display: none;
    position: fixed;
    flex-direction: row;
    gap: $spacing1;
    bottom: 20px;
    right: 20px;
    padding: $spacing2;
    background-color: $primary-grey3;
    color: $primary-grey1;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    font-size: 24px;
    z-index: 10000;
    transition: transform 0.3s ease, background-color 0.3s ease;

    &:before {
        content: "";
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        background-image: url("../static-site/assets/chat-text--white.svg");
        background-position: center center;
        background-repeat: no-repeat;
    }
}

#openBtn:hover {
    transform: scale(1.1);
}

/* Iframe container */
#iframeContainer {
    display: none;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px;
    height: 500px;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    z-index: 9999;
    background-color: #ffffff;
    transition: all 0.3s ease;
    flex-direction: column;
}

#myIframe {
    width: 100%;
    height: calc(100% - 50px);
    border: none;
}

/* Header with close and minimize buttons */
#iframeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    padding: 10px;
    height: 50px;
    box-sizing: border-box;
}

#iframeHeader button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
}

#iframeHeader button:hover {
    color: #ddd;
}

#iframeHeader .header-title {
    font-size: 16px;
    font-weight: bold;
}

/* Minimized state for iframe container */
#iframeContainer.minimized {
    height: 50px;
}

/* Webchat container */
#webchat {
    height: calc(100% - 50px);
    overflow: hidden;
    width: 100%;
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: $background-grey2;
    height: 100%;
}

.chatbot {
  &__button-container {
    display: flex;
  }

  &__minimize-btn {
    height: 24px;
    width: 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: white;
    }
  }
}

/* Chatbot bubble */
.webchat {
    font-family: Arial, Helvetica, sans-serif;

    &__stacked-layout {
        &__main {
          .webchat__stacked-layout__avatar-gutter {
              position: relative;
              z-index: 1;

              &:before {
                  content: "";
                  background-color: #1f1f1f;
                  position: absolute;
                  border: solid 1px transparent;
                  border-radius: 50%;
                  top: 0;
                  left: 0;
                  height: 40px;
                  width: 40px;
              }

              &:after {
                  content: "";
                  background-image: url("../static-site/assets/logo-simple-white.svg");
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 60%;
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1000;
              }
          }

        }

        &--from-user {
          .webchat__stacked-layout__main {
            .webchat__stacked-layout__avatar-gutter {
                &:before {
                    background-color: transparent;
                }

                &:after {
                    background-image: url("../static-site/assets/chat-text.svg");
                }
            }
          }
        }
    }

    &__suggested-actions {
      button.webchat__suggested-actions__button {
        @include remove-button-styles;
        text-decoration: none;
        text-align: center;
        font-size: $font-size-base;
        border-radius: $radius1;
        background-color: $primary-blue;
        transition: border-radius 0.2s;
        padding-left: $spacing3;
        padding-right: $spacing3;
        height: 40px;
        display: inline-flex;
        align-items: center;
        color: white;

        &:hover,
        &:link:hover,
        &:visited:hover {
          border-radius: 0;
          background-color: $primary-blue !important;
        }
      }
    }

    &__render-markdown__pure-identifier,
    &__text-content__open-in-new-window-icon,
    &__link-definitions__list-item-text {
      color: $text-color !important;

      @media (forced-colors: none) {
        color: $text-color !important;
      }
    }

  &__text-content__markdown {
    a {
      color: $text-color !important;

      &:hover,
      &:focus {
        color: $text-color !important;
      }
    }
  }

}


