.contact-list-item {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $element-color--light;
  }

  &--title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;

    @include screen-md {
      width: 50%;
    }
  }

  &--info {
    font-size: convert-to-rem(16px);


    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    @include screen-md {
      display: flex;
      justify-content: flex-start;


      &__name {
        flex-basis: 50%;
      }

      &__email {
        hyphens: auto;
        flex-basis: 30%;

      }

      &__phone {

        max-width: 132px;
        flex-basis: 30%;
        text-align: start;
      }
    }

  }
}