.text-input {
  font-size: convert-to-rem(16px);
  color: $text-color;

  &.-theme-hidden-label {
    label {
      @include visually-hidden;
    }
  }

  &--input-wrapper {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    & .icon {
      position: absolute;
      left: $spacing-md;
      color: $primary-blue;
    }

    & input {
      padding-left: $spacing-xl !important;
    }
  }

  input {
    @include input-placeholder {
      color: inherit;
      color: rgba($text-color, 0.5);
    }

    border: none;
    border-radius: $radius1;
    width: 100%;
    margin: 0;
    padding: 11px 13px;
    background: $background-grey2;
    font-size: 18px;
    color: $primary-grey3;
    border: 1px solid transparent;
    -webkit-appearance: none;

    &:focus {
      border-color: $primary-blue;
      box-shadow: 0 0 0 1px $primary-blue;
    }

    &::placeholder {
      color: $primary-grey2;
    }
  }

  /* clears the 'X' from input field in Internet Explorer */
  input[type='search'] {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from input field in from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  label {
    @include font-data-large;
    display: block;
    margin: $spacing2 0;
  }

  &.-theme-inverted {
    input {
      &:not(:focus) {
        @include input-placeholder {
          color: inherit;
          color: rgba(white, 0.5);
        }

        background-color: transparent;
        border-color: white;
        color: white;
      }
    }
  }

  &.-theme-small {
    display: flex;
    align-items: center;

    label {
      margin-right: $spacing-sm;
    }

    input {
      padding: $spacing-xs;
      border-radius: 0;
      max-width: 70px;
      text-align: center;
    }
  }

  /*
  &.-theme-rounded {
    input {
      border-radius: $border-radius-lg;
      border-color: $link-color;
      padding: 10px 20px;

      &:focus {
        border: 1px solid $focus-color;
        box-shadow: 0 0 0 1px $focus-color;
      }
    }
  }
  */

  &--validation-error {
    color: $error-red;
  }
}
