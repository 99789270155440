.event-page-link {
  display: flex;
  align-items: center;
  margin-right: $spacing-xl;
  @include font-size-sm;

  &--icon {
    margin-right: $spacing3;
    width: $spacing5;

    & .icon {
      margin: auto;
    }
  }
}