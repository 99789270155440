.employee-search-result {
  padding-top: $spacing4;

  :not(:last-child) > & {
    padding-bottom: $spacing4;
    border-bottom: 1px solid $background-grey2;
  }

  h3 {
    @include font-body-headline;
    margin: 0;
  }

  h4 {
    @include font-body;
    margin: $spacing1 0 $spacing2;
  }

  &--texts {
    @include remove-list-styles;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      @include line-separator();
      padding-right: $spacing-md;
      margin-right: $spacing-md;
    }
  }

  &--links {
    @include remove-list-styles;
    margin: 4px 0 0;
  }
}
