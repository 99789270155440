.tab-content {
  &__container {
    max-width: unset !important;

    @media screen and (min-width: 1440px) {
      margin-left: calc(((100vw - 840px) / 2) - 272px) !important;
    }
  }

  &__grid {
    grid-template-columns: 300px 1fr !important;
    -ms-grid-columns: 230px 48px 1fr;
    column-gap: 40px !important;

    @include screen-xxl {
      grid-template-columns: 432px 1fr !important;
      column-gap: 80px !important;
    }
  }

  &__content {
    max-width: $content-width-md;
  }

  &--content,
  &--downloads,
  &--link,
  &--description,
  &--text {
    margin-top: $spacing-xl;
  }

  &--bullets {
    margin-top: $spacing-lg;
  }
}
