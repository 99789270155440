.date-card-tags {
  // NOTE: Do not use flexbox here. It messes up everything in IE11
  @include cf;
  grid-area: tags;
  margin: $spacing-md 0 0;

  &-item {
    float: left;
    margin-right: $spacing1;
    margin-top: $spacing1;
    border-radius: 30px;

    .link.-theme-tag {
      @include font-data;
      background: $tags-background;
      color: inherit;
    }
  }

  &-button {
    @include font-size-sm;
    height: unset;
    padding: 4px;
  }
}
