.steps-content {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  z-index: 2;
  transition: transform 0.5s $ease-out;
  box-shadow: -2px 0 8px rgba(black, 0.2);
  font-size: 20px;

  @include screen-lg {
    position: fixed;
    width: 73vw;
    font-size: 30px;
  }

  &.-is-active {
    transform: translateX(0) !important;
  }

  &--close {
    position: sticky;
    display: flex;
    font-weight: 600;
    z-index: 2;

    &.-desktop {
      justify-content: flex-end;
      top: 4.3rem;
      top: 3.2rem;
      margin: -3.6rem 2.5rem 0 0;
      float: right;
      font-size: $font-size-np;

      & > .icon {
        margin-left: $spacing-lg;
        height: 65px;
        width: 65px;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &.-mobile {
      align-items: center;
      top: 0;
      padding-left: 1rem;
      height: 3rem;
      width: 100%;
      font-size: 16px;

      & > .icon {
        height: 1rem;
        margin-right: $spacing-sm;
      }
    }
  }
  &--component-wrapper {
    position: relative;

    @include screen-md {
      padding: 95px $spacing-xxl;
    }
  }
}
