.sticky-menu-on-tabs {
  position: relative;
  height: 100%;
  padding-bottom: $spacing-xl;

  ul {
    @include remove-list-styles;
    padding: ($spacing-sm / 2) 0;
    margin: 0;
  }

  li {
    padding: ($spacing-sm / 2) 0;
  }

  &--content {
    @include font-size-sm;
    position: sticky;
    top: 0;
    margin-top: -$spacing-xl;
    max-height: calc(100vh - #{$spacing-xl});
  }

  &--title {
    @include font-data-large;
    border-bottom: 1px solid #00000052;
    padding-bottom: $spacing2;
    margin: $spacing7 0 $spacing4;
  }

  &--title-link {
    font-weight: 600;
    margin-top: 0;

    &:not(:first-child) {
      margin-top: $spacing-md;
    }
  }

  .action-link-icon {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
}
