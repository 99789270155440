.description-list-block {
  &--title {
    @include fluid-type(28px, 24px);
    font-weight: 400;
  }

  dl {
    background-color: $element-color--lightest;
    padding: $box-padding;

    span {
      display: block;
      border-bottom: 1px solid $element-color--light;
      padding: $spacing-sm 0;

      @include screen-sm {
        display: flex;
        justify-content: space-between;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
  }
}
