.map-popup {
  @include font-size-sm;
  min-width: 300px;
  padding: $spacing-md;
  padding-right: 0;
  font-family: $font-stack;
  font-weight: normal;
  color: $element-color--dark;
  text-align: left;

  h2 {
    font-size: inherit;
    color: black;
    margin: 0;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  &--text {
    margin-top: $spacing-lg;
  }
}
