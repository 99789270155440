.rich-text-block {

  .heading {
    margin-bottom: $spacing-lg;
  }

  max-width: $content-width-l;
  margin-left: auto;
  margin-right: auto;

  @include screen-lg {
    padding: 0 16px;
  }

  &.-theme-no-padding {
    @include screen-lg {
      padding: 0;
    }
  }
}