.shape {
  z-index: 1;
  &.-red {
    color: $np-method-red;
  }
  &.-orange {
    color: $np-method-orange;
  }
  &.-teal {
    color: $np-method-teal;
  }
}
