.share-options {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  padding: $spacing5;

  &__close-button {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    width: 100%;
    padding: $spacing-sm $spacing-sm 0 0;

    &--icon {
      color: $link-color;
      &:hover {
        color: black;
      }
    }
  }

  &__options {
    @include font-data-large;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: baseline;
    background-color: white;
  }

  &__link-container {
    @include screen-sm {
      width: 100px;
    }
    width: 50px;
    margin-right: $spacing-xxl;
    margin-top: $spacing-sm;
    &:nth-child(-n + 2) {
      margin-top: 0;
    }
  }

  &__link {
    a {
      @include underline-animation('span');
      display: inline-flex;
      color: $primary-grey3;
      align-items: center;
      text-decoration: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: $spacing-sm;
      margin-bottom: 2px;
      fill: none;
      stroke: none;
    }
  }

  &__copy {
    @include screen-sm {
      display: flex;
    }
    width: 100%;
    display: block;
    margin-top: $spacing-md;
    align-items: center;
  }

  &__textarea {
    @include font-size-sm;
    @include screen-sm {
      margin-right: $spacing-lg;
      width: 70%;
      padding: $spacing-sm $spacing-md;
    }
    border: 1px solid $focus-color;
    width: 100%;
    padding: $spacing-sm;
    resize: none;
  }

  &__copy-button {
    @include screen-sm {
      margin: 0;
    }
    margin-top: $spacing-sm;
    float: right;
  }
}
