.tooltip {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.95);
  padding: $spacing3 $spacing5;
  color: $text-color;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  width: max-content;

  @include IE {
    width: 400px; // Alternative to max-content
  }

  &.-tab-triggered {
    position: absolute;
    left: 100%;
  }
}
