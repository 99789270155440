.proposal-contact {
  @include font-body;
  display: inline;

  .link--text {
    @include underline-animation-fill('span');
    color: $primary-grey3;

    &:not(:last-child):after {
      content: ', ';
    }
  }

  span,
  a {
    text-decoration: none;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: $spacing-sm;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 1em;
        background-color: $element-color--light;
        margin-left: $spacing-sm;
      }
    }
  }
}
