.menu-search {
  position: relative;
  margin-top: $spacing-lg;

  @include screen-lg {
    display: none;
  }

  &--input {
    width: 100%;
  }

  &--input:focus + &--icon {
    display: none;
  }

  &--icon {
    position: absolute;
    top: 50%;
    right: $spacing-md;
    transform: translateY(-50%);
  }

  &--submit {
    @include visually-hidden-button;
  }
}
