.article-header {
  color: $text-color;
  margin-bottom: $spacing-lg;

  .byline {
    font-family: 'IBM';
    text-transform: uppercase;

    @media print {
      font-size: 0.5rem;
    }
  }

  &.-is-large-article {
    .-is-mobile {
      display: none;
    }

    .byline--item {
      float: left;

      &:not(:last-child) {
        @include line-separator;

        margin-right: 10px;
        padding-right: 10px;
      }
    }

    .-is-desktop {
      @include screen-sm-max {
        display: block;

        .byline {
          margin-bottom: $spacing-sm;
        }
      }
    }

    .article-header--links {
      display: flex;
    }
  }

  &--byline {
    @include font-size-xs;
    width: 100%;
    border-bottom: 1px solid $element-color--light;
    padding: $spacing-md 0;

    &.-is-desktop {
      display: flex;

      @include screen-sm-max {
        display: none;
      }
    }

    &.-is-mobile {
      @include screen-sm {
        display: none;
      }

      p {
        margin: auto;
      }
    }

    @media print {
      @include font-size-xs;
    }
  }

  &--byline-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'IBM';
    text-transform: uppercase;

    > p {
      margin: 0;
      flex-grow: 1;
    }
  }

  &--toggle {
    color: $link-color;

    &.-is-active {
      transform: rotate(-180deg);
    }
  }

  &--byline-content {
    display: flex;
    flex-direction: column;

    .options-modal__button {
      @include screen-sm-max {
        padding: 0px;
      }
    }
  }

  &--download-link {
    @include line-separator('before', 'left');
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    margin-left: $spacing-sm;
    align-self: flex-end;
    padding-left: 10px;
    font-family: 'IBM';
    text-transform: uppercase;
  }

  &--download-label {
    a {
      color: $primary-grey3 !important;
      font-family: 'IBM';
      text-transform: uppercase;
    }
  }

  &--links {
    display: block;
    margin-top: $spacing2;
    margin-left: 0;

    @include screen-sm {
      display: flex;
      margin-left: auto;
    }

    @media print {
      display: none;
    }

    &.-is-desktop {
      margin-top: 0;
    }

    .options-modal__button {
      font-family: 'IBM';
      text-transform: uppercase;
      color: black;
    }

    .article-header--download-link {
      .link {
        > span {
          align-items: center;
        }

        color: black;
      }
    }
  }
}
