.screen9-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.338%;
}

.screen9-video-iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
