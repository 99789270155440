.dates-more-link {
  position: relative;
  margin: 0 $spacing3 $spacing3 0.7rem;
  border-top: 1px solid $divider-grey1;

  &--link {
    font-size: 16px !important;
    margin-top: $spacing3;
  }
}
