.options-modal {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  &.-is-text-with-sidebar {
    .options-modal__button {
      padding: 0;
    }
  }

  &__download-list {
    padding: $spacing-lg;
  }

  &__button {
    margin: 0;
    font-size: convert-to-rem(14px);
    color: $link-color;
    height: auto;

    .-is-proposal-or-event & {
      padding-right: 0 !important;

      & .icon {
        margin-right: 16px !important;
      }
    }

    &:hover {
      color: $text-color;
      cursor: pointer;
    }
  }

  &__close-button {
    padding: 8px !important;
    position: absolute;
    right: 6px;
    top: 4px;

    &--icon {
      color: $text-color;
      width: 18px;
      height: 18px;

      &:hover {
        color: black;
        cursor: pointer;
      }
    }
  }

  &__options {
    @include font-size-sm;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    background-color: white;
  }

  &__modal-container {
    background-color: white;

    @include screen-lg-max {
      left: 0;
      min-width: 100%;
    }

    @include screen-lg {
      transform: translate(-320px);
      margin-top: $spacing-sm;
      width: 800px;

      .-is-article-header & {
        transform: translate(-610px);
      }

      .-is-page-footer & {
        transform: translate(-395px);
      }

      .-is-proposal-or-event & {
        transform: none;
      }

      .-is-text-with-sidebar & {
        transform: translate(23px);
      }
    }

    position: absolute;
    z-index: 99;
    width: 90%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

    &--hidden {
      display: none;
    }
  }
}
