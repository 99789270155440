.error-page {
  position: relative;
  background-color: $background5;
  height: 100vh;
  padding: 1rem;
  overflow: hidden;

  @include screen-md {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &--content {
    position: relative;
    width: 100%;
    margin-top: 10%; // Offset from top
    @include screen-md {
      margin-top: -10%; // Offset from center
    }
  }

  &--icon {
    position: absolute;
    top: -110px;
    right: -27rem;

    @include screen-md {
      right: -15rem;
    }

    @include screen-xl {
      right: 0;
    }
  }

  &--content-inner {
    max-width: 500px;
    margin: 0 auto;

    // Necessary to be stacked above the "error-icon" sibling that
    // has absolute positioning.
    position: relative;
  }

  &--code {
    @include fluid-type(32px, 32px);
    font-weight: 600;
    line-height: 32px;
    margin: 0 0 $spacing4;
  }

  &--title {
    @include fluid-type(48px, 48px);
    line-height: 50px;
  }

  &--message {
    font-size: convert-to-rem(36px);
    line-height: 1.4;
  }

  &--link {
    margin-top: 1rem;
    @include fluid-type(14px, 14px);
  }

  &--text {
    @include fluid-type(18px, 18px);
    margin-top: $spacing4;
  }
}
