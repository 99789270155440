@mixin screen-content-width {
  @media (max-width: $content-width-xl) {
    @content;
  }
}

.tab-list {
  @include font-body;
  position: relative;
  border-bottom: 1px solid $element-color--light;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 1px;
    background-color: $element-color--light;
  }

  ul {
    @include remove-list-styles;
    display: flex;
    width: 100%;
    margin: 0;
  }

  li {
    position: relative;
    font-weight: 400;
    color: $primary-grey3;
    min-width: 58px;

    @include screen-md {
      margin: 2px 2px 0;
      margin-right: 2em;

      .-has-hover-effect:hover {
        text-decoration: none;
        margin-bottom: -1px;
        border-bottom: 4px solid $primary-grey2;
        color: $text-color;
      }
    }

    &.-is-active {
      min-width: auto;

      & a {
        margin-bottom: -1px;
        border-bottom: 4px solid $primary-blue;
        color: $text-color;
      }
    }

    &.disabled {
      color: $disabled;
    }
  }

  a {
    display: block;
    padding: $spacing-md $spacing-md $spacing-sm;

    color: inherit !important;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @include screen-md {
      text-align: left;
    }

    &.link:hover {
      text-decoration: none !important;
      color: $primary-grey3 !important;
    }
  }
}

.-limited-width {
  max-width: $content-width-xl;
  margin: auto;

  @include screen-content-width {
    margin: 0 34px;
  }

  @include screen-md-max {
    margin: 0 $spacing-lg;
  }
}