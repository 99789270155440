.portfolio-tile {
  &.category-apple {
    background: #d2dade;
    border: 1px solid #612141;
  }
  &.category-building {
    background: #00bee2;
  }
  &.category-democracy {
    background: #cec0ea;
  }
  &.category-factory {
    background: #00338d;
    color: white;
  }
  &.category-grain {
    background: #71cc98;
  }
  &.category-handshake {
    background: #a07a8d;
  }
  &.category-heart {
    background: #a50050;
    color: white;
  }
  &.category-iceberg {
    background: #e6f8fc;
    border: 1px solid #00bee2;
  }
  &.category-oil-drum {
    background: #99add1;
  }
  &.category-people {
    background: #612141;
    color: white;
  }
  &.category-science {
    background: #fdf4eb;
    border: 1px solid #71cc98;
  }
  &.category-tree {
    background: #ebfbf3;
    border: 1px solid #71cc98;
  }
  &.category-windmill {
    background: #1c445a;
    color: white;
  }
  &.category-globe {
    background: #ad96dc;
  }
  &.category-ocean {
    background: #8bd3e6;
  }
  &.category-sami {
    background: #d7c7cf;
  }

  & > .icon {
    margin-top: auto;
    height: 48px;
    width: 48px;
    transition: transform 0.3s;
  }
}
