.steps-block {
  display: flex;
  background: $element-color--lighter;
  min-height: 25rem;
  padding: $np-block-padding;
  height: 500px;
  min-width: 100%;
  transition: transform 0.5s;

  @include screen-md {
    padding: $spacing-xl $spacing-xxl;
  }

  &.-remove-transition {
    transition: unset;
  }
  &:nth-child(2) {
    transform: translateX(calc(100% + #{$spacing-xxl}));
  }

  &.-animate-left {
    transform: translateX(calc(-100% - #{$spacing-xxl}));

    &:nth-child(2) {
      transform: translateX(-100%);
    }
  }

  &-list {
    display: flex;
  }

  &--snake {
    position: absolute;
    top: 3rem;
    right: 0;
    transform-origin: right;
    transform: scale(0.6);

    @include screen-md {
      top: 7rem;
      transform: scale(1);
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1;

    &-page-number {
      display: block;
      font-size: 20px;
      margin-bottom: $spacing-sm;

      @include screen-lg {
        font-size: 30px;
      }
    }

    &-title {
      font-size: 32px;
      font-family: $font-family-np;
      margin: 0 0 $spacing-md;

      @include screen-lg {
        font-size: 48px;
      }
    }

    &-paragraph {
      margin: 0;
      font-family: $font-family-np;
    }

    &-button-container {
      margin-top: auto;
    }

    &-button {
      margin-top: auto;
      @include screen-md-max {
        padding: 10px $spacing-lg !important;
      }
    }
  }

  &--image {
    display: flex;
    flex: 1;
    height: 100%;

    & .image {
      max-height: 100%;
      margin-top: auto;
      margin-left: auto;
    }
  }
}
