.link-wrapper {
  &__outer-link {
    color: $text-color !important;
  }

  &__outer-link:hover &__inner-link {
    color: $text-color;
    text-decoration: underline;
  }

  &__inner-link {
    @include font-size-xl;
    margin-top: 0;
    display: block;
    font-weight: normal;
    color: $link-color;
  }
}
