.checkbox {
  display: block;
  color: $text-color;

  &.-theme-gray {
    input {
      @include visually-hidden;

      &:checked+label {
        color: black;

        span {
          color: inherit;
        }
      }
    }

    label {
      position: relative;
      display: flex;
      align-items: flex-start;

      &:hover {
        color: black;
      }

      &:before {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        border: 1px solid $background-color;
        border-radius: 2px;
        background-color: white;
      }

      span {
        padding-left: $spacing-sm;
      }

      svg {
        color: $link-color;
      }
    }
  }

  &.-theme-gray {
    color: $element-color--darker;
  }

  &.-theme-blue {
    input {
      @include visually-hidden;

      &:checked+label {
        color: black;

        span {
          color: inherit;
        }


        &:before {
          background-color: $primary-blue;
          border: unset;
        }

      }
    }

    label {
      position: relative;
      display: flex;
      align-items: flex-start;

      &:hover {
        color: black;
      }

      &:before {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border: 2px solid $background-color;
        background-color: white;
      }

      span {
        padding-left: $spacing-sm;
      }

      svg {
        color: white;
      }
    }
  }

  html:not(.mouse-user) & input:focus+label {
    @include focus-outline;
  }

  &--checkmark {
    position: absolute;
    left: 3.5px;
    top: 3px;
    transform: scale(0);
    transition: transform 0.2s $ease-out;

    input:checked+label & {
      transform: none;
    }
  }
}