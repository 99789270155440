.stats-link {
  width: 70%;

  &:not(:last-child) {
    margin-bottom: $spacing-md;
  }

  @include screen-md {
    width: calc(100% / 3);
    padding: 0 $spacing-lg;
  }

  &--top {
    @include screen-md-max {
      display: flex;
    }
  }

  &--image {
    @include screen-md {
      margin: auto;
    }
  }

  &--title {
    @include fluid-type(32px, 60px);
    margin: $spacing-lg 0 $spacing-md;
    align-self: center;
  }

  &--link {
    @include fluid-type(20px, 24px);
  }
}
