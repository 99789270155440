blockquote {
  position: relative;
  margin: $spacing-xl 0;
  text-align: center;

  span {
    @include font-display2;
    color: $primary-blue;
  }

  > p {
    @include fluid-type(21px, 36px);
    font-weight: 500;
    line-height: 1.33;
    color: black;
    margin: -15px 0 0 0;
  }

  cite {
    @include font-data;
    text-transform: none;
    font-style: normal;
    color: black;
    display: block;
    margin: $spacing4 0;
  }
}
