.list-item-image {
  position: relative;
  margin: 0 $spacing-md 1rem 0;
  width: calc(#{$list-image} * 0.9);

  &.-size-small {
    width: 120px;
  }

  @include screen-md {
    width: $list-image;
  }

  &__rectangle {
    position: relative;
    height: 0;
    padding-bottom: 75%;
  }
}
