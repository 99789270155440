.large-article-page {
  &--content {
    @include screen-lg {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1024px;
    }

    &-main {
      @include screen-lg-max {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
      }
    }
  }

  &--menu-anchor {
    position: relative;
  }

  &--image {
    position: relative;
    height: 20rem;
  }

  &--sidebar {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include screen-lg {
      align-self: start;
      top: 0;
    }
  }

  &--title {
    font-size: convert-to-rem(40px);

    @include screen-lg-max {
      margin-top: $spacing-xl;
    }
  }
}

.article-metadata {
  align-items: flex-start;
  background-color: $blue-light;
  display: flex;
  gap: $spacing-md;
  flex-direction: column;
  padding: $spacing-md;

  @include screen-lg-max {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  &--title {
    @include font-headline3;
    margin: 0;
  }

  &--list {
    color: $undrelab-purple;
    margin: 0;

    dt {
      @include font-body-headline;
    }

    dd {
      @include font-body;
      margin: $spacing-xs 0 $spacing-md;
    }

    dd:last-child {
      margin-bottom: 0;
    }
  }
}