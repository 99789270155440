.metadata {
  @include font-body;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: $spacing-md 0;

  &.-theme-vertical {
    display: block;
    line-height: 125%;

    dt {
      @include screen-sm {
        float: left;
      }
    }

    dd:after {
      display: none;
    }

    .metadata--icon {
      float: left;
    }

    &--vacancies {
      dd {
        margin-bottom: 4px;
      }
    }
  }

  dt {
    display: flex;
    align-items: center;
    margin-right: 0.2rem;
  }

  dd {
    padding-right: $spacing-md;
    margin: 0 $spacing-md 0 0;

    &:not(:last-child) {
      @include line-separator;
    }
  }

  &--icon {
    margin-right: $spacing-sm;
  }

  & &--data--disabled {
    color: $element-color--darker;
    background: $element-color--lighter;
    padding: 0 $spacing-xs;
  }
}
