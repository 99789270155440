.event-list-item {
  display: flex;
  padding-bottom: 38px;
  border-bottom: 1px solid $divider-grey4;
  margin-bottom: 38px;

  &--dates-more {
    top: -10px;
  }

  &:first-of-type {
    margin-top: 38px;
  }

  @include screen-md-max {
    display: block;
  }

  .card {
    > *:not(.card--image) {
      display: none;

      @include screen-md-max {
        display: flex;
        flex-direction: column;

        .card--date {
          order: 1;

          .card--date-dot {
            display: block;
            transform: scale(1);
          }

          &-text {
            transform: translateX(16px);
          }
        }

        .card--title {
          order: 2;
          margin-bottom: 16px;
        }

        .data-tag-list {
          order: 3;
          margin-top: unset;
          margin-bottom: 32px;
        }

        .card--text {
          order: 4;
          margin-bottom: unset;
        }
      }
    }

    .card--image {
      min-height: 198px;
      min-width: 198px;
      aspect-ratio: 1;

      @include screen-md {
        height: 198px;
        width: 198px;
      }
    }

    &--title {
      @include font-headline3;
      text-decoration: none;
      color: $text-color;

      &:hover {
        text-decoration: underline;
      }

      .heading {
        @include font-headline3;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }
  }

  &:hover {
    .fluid-image--image {
      transform: scale(1.05);
    }

    .card--date-dot {
      transform: scale(1);
    }

    .card--date-text {
      transform: translateX(16px);
    }
  }

  &--info-section {
    margin-left: 32px;

    @include screen-md-max {
      margin-left: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date-section {
        justify-content: space-between;
        width: 100%;
      }

      .dates-more--button {
        margin-left: auto;
      }

      .card-info-desktop {
        display: none;
      }
    }

    .data-tag-list {
      margin-bottom: 32px;
    }

    .card--date {
      margin-top: 0px;
    }

    .date-section {
      display: flex;
      align-items: center;
      margin-top: 32px;
      position: relative;

      .event-card-list--date {
        @include font-small-headline;
      }
    }
  }
}
