.date-card-dates {
  $titleMargin: 10px;
  position: relative;
  grid-area: date;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-content: center;
  padding-bottom: calc(#{$spacing-lg} - #{$titleMargin});
  min-height: 120px;

  @include screen-md {
    justify-content: center;
    margin-top: -$spacing-lg;
    padding-top: $spacing-md;
    min-height: 145px;
  }

  &.-has-more-dates {
    $dates-more-height: 36px;
    margin-bottom: $dates-more-height; // prevents dates-more from overlapping content in closed state.

    @include screen-md-max {
      padding: 0 !important;
      margin-bottom: calc(#{$dates-more-height} + #{$spacing-md});
    }
  }

  &.-single-period {
    align-items: baseline;
  }

  &.-light-text {
    color: white;
    padding: $spacing-md;
  }

  &.-is-planned {
    color: $element-color--darker;
    border: 2px dashed $element-color--dark;
    padding: $spacing-md;
  }

  &.-theme-big {
    margin-top: 0 !important;
    color: white;
    height: 70px;
    width: 100%;
    min-height: unset;

    @include screen-md {
      padding: $spacing-xl $spacing-lg;
      justify-content: center !important;
      min-height: 270px;
    }

    &.-has-more-dates {
      margin-bottom: 70px;
    }

    & .date-card-dates {
      &--bar {
        &:before {
          width: 52px;
          height: 6px;
        }
      }
      &--day {
        font-size: convert-to-rem(40px);
        @include screen-md {
          font-size: convert-to-rem(60px);
        }
      }

      &--month {
        font-size: 1.2rem;

        @include screen-md {
          font-size: convert-to-rem(24px);
        }
      }

      &--past-date {
        margin: 0 auto;
      }
    }
  }

  &.-is-past-date {
    background-color: $element-color--lightest;
    color: $element-color--darker;
    padding: 0.3rem $spacing-md 1.7rem;
    justify-content: center;

    @include screen-md {
      padding: $spacing-lg 0;
    }

    .event-image {
      display: none;
    }

    &--bar {
      display: none;
    }

    &--description-top {
      color: $element-color--darker;
    }
  }

  &--dates {
    display: flex;
    position: relative;
  }

  &--date {
    z-index: 1;
    display: flex;
    padding: 1.5rem 1rem;
  }

  &--bar {
    position: relative;
    width: 100%;
    margin-top: $spacing-md;

    &:before {
      @include orange-bar;
      position: relative;
      display: block;
      margin: 0 auto;

      @include screen-md-max {
        @include orange-bar(auto, 0, 24px, 3px);
      }
    }
  }

  &--description {
    width: 100%;
    line-height: 1;
    text-transform: uppercase;

    .date-card:not(.-theme-sidebar) & {
      @include screen-md {
        text-align: center;
      }
    }
  }

  &--past-date {
    align-self: center;
    margin-left: 1rem;

    @include screen-md {
      width: 100%;
      margin-left: 0;
      margin-bottom: $spacing-lg;
    }
  }

  &--past-date,
  &--description-top {
    @include font-size-xs;
    text-transform: uppercase;
    font-weight: bold;
    hyphens: auto;
    z-index: 1;

    @include screen-md {
      margin-top: $spacing-md;
      text-align: center;
    }
  }

  &--description-top {
    width: 100%;
    margin-top: 0;
    margin-bottom: $spacing-sm;
    margin-top: $spacing-sm;
  }

  &--day {
    @include font-size-heading;
  }

  &--month {
    text-transform: uppercase;
  }

  &--day,
  &--month,
  &--year {
    margin-right: $spacing-xs;

    @include screen-md {
      margin: 0;

      &.-single-period {
        width: 100%;
        position: absolute;
        left: 0;
        margin: 0 !important;
      }
    }
  }

  &--date {
    display: flex;
    align-items: baseline;

    @include screen-md {
      display: block;
      text-align: center;
      line-height: 1;
    }
  }

  &--date + &--date {
    .date-card-dates--day {
      display: flex;

      &:before {
        content: '–';
        left: 0;
      }
    }

    .date-card:not(.-theme-sidebar) & {
      @include screen-md {
        .date-card-dates--month {
          margin-left: 1rem;
        }

        .date-card-dates--year {
          margin-left: 1rem;
        }
      }
    }
  }

  &--international {
    width: 100%;
    margin-top: $spacing-md;
    z-index: 1;

    @include screen-md {
      display: flex;
      justify-content: center;
    }

    &-icon {
      width: 40px;
      height: 40px;
      color: $focus-color;
    }
  }
}
