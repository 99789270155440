.map {
  position: relative;
  height: 100%;

  &--controls {
    position: absolute;
    bottom: $spacing-lg;
    right: $spacing-lg;
    background-color: white;
    border-radius: 2px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: $spacing-sm;
      right: $spacing-sm;
      height: 1px;
      background-color: $element-color;
    }
  }

  &--button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $link-color;
    height: 44px;
    width: 44px;
  }

  &--container {
    width: 100%;
    height: 100%;
  }
}
