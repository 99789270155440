.background-hover-effect {
  display: none;

  @include cursor-screen-only {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 0;

    &.-filter-color {
      box-shadow: 0 0 0 1px $element-color--light;
      background: $element-color--light;
      height: 100%;
      top: 0;
    }

    &.-menu-color {
      background: $primary-grey2;
      transform: translateY(-100%);
    }

    &__trigger:hover ~ & {
      display: block;
    }

    &__trigger {
      position: relative;
      z-index: 1;
    }
  }
}
