.image-block {
  &.-has-title {
    border: 1px solid $element-color--light;
    padding: $box-padding;
    min-height: 0;
  }

  h2 {
    margin-top: 0;
  }
}
