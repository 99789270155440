.sticky-menu-on-tabs-item {
  .fluid-image--image {
    background-repeat: no-repeat;
    background-position: -23px 0 !important;
  }

  &:hover {
    .fluid-image--image {
      background-position: 0px 0 !important;
    }
  }
}
