.navigation-list {
  &__toggle {
    width: 100%;
    color: white;
    font-size: inherit;
    z-index: 1;
    padding: 0;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
    }

    &.-is-active > .icon {
      transform: rotateZ(-180deg);
    }

    &-text {
      text-align: left;
      margin-right: auto;
      color: white;
    }
  }

  &__content {
    @include fluid-type(16px, 16px);
    font-weight: 500;
  }

  .link-list {
    padding: $spacing3 0;

    & > li {
      padding: 0;
    }

    .link--text {
      @include underline-animation('span');
      color: white;
      cursor: pointer;
    }
  }
}
