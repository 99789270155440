.global-search {
  position: relative;
  transition: width 0.5s $ease-out;

  &__form {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    visibility: hidden;

    &.-is-visible {
      visibility: visible;
    }
  }

  &__input {
    @include font-size-body;
    width: 100%;
    height: 100%;

    .icon {
      color: black;
    }

    input {
      background: $primary-grey1;
      padding: $spacing1 $spacing2 $spacing1 42px !important;
      border: none !important;
      box-shadow: none !important;
      color: $primary-grey3;
      font-size: 1rem;

      &::placeholder {
        color: $primary-grey3;
      }
    }
  }

  &--open {
    width: 100%;
    background: $primary-grey1;
  }

  &__submit {
    @include visually-hidden-button;
  }
}
