.event-data {
  @include font-body;

  &--item {
    margin-bottom: $spacing-sm;

    &:first-child {
      margin-bottom: $spacing5;
    }

    & dt {
      display: inline;
    }

    & dd {
      margin-left: 0.2rem;
      display: inline;
    }

    &.-multiple-paragraphs {
      display: flex;

      & p {
        display: block;
      }
    }

    p {
      margin: 0;
      display: inline;

      span {
        display: inline;
      }

      span~span {
        margin-left: 0.3rem;
      }
    }
  }
}