.accordion-container {
  &--button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    padding: 1rem 0 !important;
    border-bottom: 1px solid $border-grey !important;
    border-radius: 0 !important;

    &-text {
      margin: 0;
      color: $text-white;
    }
  }

  &--icon {
    @include accordion-icon;
    color: white;
  }

  &--content {
  }
}
