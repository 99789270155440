.timeline-item {
  color: $primary-grey3;
  @include font-size-sm;
  position: relative;
  padding-left: 5vw;

  @include screen-sm {
    padding-left: $spacing-xxl;
  }

  @include screen-md {
    padding-left: $spacing-xl;
  }

  &.-theme-orange {
    .timeline-item--line {
      color: $focus-color;
    }
  }

  &.-is-last &--line {
    border-bottom: none;
  }

  &--title {
    @include font-data-large;
    color: $primary-grey3;
    height: 40px;
    margin-left: $spacing-xxl;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include screen-sm {
      margin: 0;
    }
  }

  &--sub-title {
    @include font-body-headline;
    margin: 30px 0 0 $spacing-xxl;

    @include screen-sm {
      margin: 30px 0 0;
    }
  }

  &--text {
    @include font-body;
    margin-left: $spacing-xxl;
    padding-top: $spacing1;
    padding-right: $spacing-xl;

    @include screen-sm {
      margin: 0;
      padding-right: $spacing-lg;
    }
  }

  &--progress {
    position: absolute;
    top: 40px;
    z-index: 0;
    width: 0;
    left: 0.5rem;
    height: 1px;
    border-bottom: 2px dashed $primary-grey3;
    transform: translateX(5vw);
    margin-left: $spacing-xxl;

    @include screen-sm {
      transform: translateX(0);
    }

    @include screen-md {
      margin: 0;
      transform: translateX($spacing-xl);
    }
  }

  &--line {
    position: absolute;
    top: 40px;
    z-index: 0;
    width: 100%;
    left: 0%;
    height: 1px;
    border-bottom: 2px dashed $primary-grey3;
    transform: translateX(5vw);
    margin-left: $spacing-xxl;

    @include screen-sm {
      transform: translateX(0);
    }

    @include screen-md {
      margin: 0;
      transform: translateX($spacing-xl);
    }

    :last-child > .timeline-item & {
      width: 220%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: $spacing-md;
        width: 1px;
        background-color: currentColor;
        transform: translateY(-50%);
      }
    }

    &-gap {
      position: absolute;
      height: 1rem;
      background: white;
      z-index: 1;
      top: -3px;
      width: 3px;
    }
  }
}

.ballmaker {
  &:before {
    @include timeline-marker;
    top: 49%;
    background-color: $primary-yellow-green;
    transform: translateY(-6px);

    @include screen-sm {
      margin: 0;
    }

    .-is-active & {
      border: 4px solid $primary-grey3;
      background-color: white;
    }
  }

  &--progress:before {
    background-color: transparent;
  }
}
