.satellite-header {
  &__top {
    padding-top: 2rem;
    padding-bottom: 4rem;
    position: relative;
    text-align: center;

    &--large {
      min-height: 19rem;
    }
  }

  &__lead {
    @include font-size-xl;
    margin: 0 auto -3rem;
    max-width: 900px;
    width: 90%;
    text-shadow: 0 0 3px white;

    &.-light-text {
      color: white;
      text-shadow: 0 0 3px black;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 9rem;
    justify-content: center;
    align-items: center;

    @include screen-md {
      flex-direction: row;
    }
  }

  &__link {
    margin: $spacing-sm;
    width: max-content;
  }

  &__image {
    z-index: -1;
  }

  &__bottom {
    max-width: $content-width-sm;
    margin: -6rem auto 0;
    padding: 0 1rem $spacing-xl;
    background: white;
  }

  &__ingress {
    @include font-size-lg;
  }

  &__title {
    @include font-size-heading;
    text-align: center;
    padding: $spacing-md;
  }
}
