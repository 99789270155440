.filter-group {
  border-bottom: 1px solid $divider-grey2;
  padding: $spacing2 0 !important;

  &--options,
  &--sub-options {
    @include remove-list-styles;
    @include font-large;
    margin: 0;
  }

  &--sub-options {
    padding: $spacing-sm 0 0 $spacing-lg;
  }

  &__content {
    padding: $spacing2 0 $spacing-xs;

    li {
      margin-bottom: $spacing-sm;
    }
  }

  &--header {
    width: 100%;
    padding: 0 !important;
    height: auto !important;
    cursor: pointer;

    &-title {
      @include font-large-subtitle;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      text-align: left;

      span {
        flex: 1;
        margin-right: 1rem;
      }
    }
  }

  &--icon {
    @include accordion-icon;
    margin-right: $spacing-xs;
  }
}
// .filter-group {
//   position: relative;
//   border-bottom: 1px solid $element-color--light;
//
//   &__toggle {
//     width: 100%;
//     padding: 21px 15px 21px 0;
//     border-radius: 25px;
//
//     &-title {
//       margin-right: auto;
//     }
//
//     &.-is-active {
//       border-bottom-right-radius: 0;
//       border-bottom-left-radius: 0;
//       & > .icon {
//         transform: rotateZ(-180deg);
//       }
//     }
//
//     &.-is-deactive {
//       @include delay(
//         0,
//         25px,
//         0.5s,
//         70%,
//         border-bottom-left-radius,
//         border-bottom-right-radius
//       );
//     }
//   }
//
//   &--options,
//   &--sub-options {
//     @include remove-list-styles;
//     margin: 0;
//   }
//
//   &--sub-options {
//     padding: $spacing-sm 0 0 $spacing-lg;
//   }
//
//   &__content {
//     padding: $spacing-sm 0 $spacing-lg;
//
//     li {
//       margin-bottom: $spacing-sm;
//     }
//   }
//
//   &__submit {
//     width: 100%;
//     margin-top: $spacing-lg;
//   }
// }

.filter-group-open {
  margin-top: $spacing-md;

  &:first-child {
    margin-top: 0;
  }

  &__title {
    @include font-body-headline;
    margin: $spacing-lg 0 $spacing-md 0;
  }

  &--options,
  &--sub-options {
    @include remove-list-styles;
    margin: 0;
    font-size: 18px;
  }

  &--sub-options {
    padding: $spacing-sm 0 0 $spacing-lg;
  }

  &__content {
    li {
      margin-bottom: $spacing-sm;
    }
  }
}
