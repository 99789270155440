.content-area {
  &.-theme-two-columns,
  &.-theme-three-columns,
  &.-theme-four-columns,
  &.-flex-layout {
    margin-left: -($box-padding / 2);
    margin-right: -($box-padding / 2);
  }

  &.-theme-two-columns,
  &.-theme-three-columns,
  &.-theme-four-columns {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    > div {
      padding: 0 ($box-padding / 2);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &.-theme-portfolio {
    margin-left: -$box-padding;
    margin-right: -$box-padding;

    & > div {
      padding: 0 $box-padding;
    }
  }

  &.-theme-single-column {
    width: 100%;
  }

  &.-theme-two-columns {
    > div {
      @include screen-md {
        width: 50%;
      }
    }
  }

  &.-theme-three-columns {
    > div {
      @include screen-md {
        width: 33.3%;
      }
    }
  }

  &.-theme-four-columns {
    > div {
      @include screen-md {
        width: 25%;
      }
    }
  }

  &.-flex-layout {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
