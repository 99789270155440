.card {
  text-decoration: none;
  color: $primary-grey3;

  &:hover {
    .fluid-image--image {
      transform: scale(1.05);
    }

    .card--date {
      .card--date-dot {
        transform: scale(1);
      }

      &-text {
        transform: translateX(16px);
      }
    }
  }

  &--image {
    @include aspect-ratio(16 / 9);
    position: relative;
    width: 100%;

    @media print {
      aspect-ratio: unset;
    }
  }

  &--date {
    @include font-data-large;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: $spacing4;

    &-dot {
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      background: black;
      border-radius: 100%;
      transform: scale(0);
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &-text {
      transition: transform $transition;
    }
  }

  &--title {
    @include font-large-headline;
    margin: $spacing2 0 $spacing1;

    @media print {
      word-break: break-word;
    }
  }

  &--text {
    @include font-small;
    margin-top: 0;
  }

  .fluid-image--image {
    transition: transform $transition;
  }
}