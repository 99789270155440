.nested-link {
  &--title {
    @include fluid-type(30px, 30px);
    font-weight: 500;
    line-height: 125%;
    margin: 0;
    padding-bottom: $spacing3;
    border-bottom: 1px solid $divider-grey2;
  }
}
