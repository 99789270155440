.embed-block {
  background-color: $element-color--lightest;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &:not(.-has-dimensions) {
    iframe {
      width: 100%;
      height: auto;
    }
  }

  &.-has-dimensions {
    padding: $box-padding;
  }

  iframe {
    border: none;
  }
}
