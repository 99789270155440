.newsletter-updated-page {
  li {
    font-weight: bold;

    div {
      font-weight: normal;
    }
  }

  &--section {
    margin-top: $spacing-xl;
  }
}
