.page-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $element-color--lighter;
  padding-top: $spacing-md;
  color: $text-color;

  &--byline-wrapper {
    @include screen-sm {
      display: flex;
      align-items: flex-start;
    }
    width: 100%;
    display: block;
  }

  &--links {
    display: block;
    align-items: center;
    @include screen-sm {
      margin-left: auto;
      display: flex;
    }
  }

  &--download-link {
    @include font-size-xs;
    padding-right: 10px;
  }
  &--share {
    width: 100%;
    @include screen-sm {
      margin-right: $spacing-sm;
      width: auto;
    }
    &.-with-seperator {
      padding: 0;
      @include screen-sm {
        @include line-separator("before", "left");
        padding-left: 10px;
      }
    }
  }
}
