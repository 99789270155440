.process {
  margin-top: 3rem;

  @media print {
    display: none;
  }

  &--title {
    @include font-headline2;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &--items {
    column-gap: $spacing6 !important;
  }

  &-print {
    padding: 0 1rem;

    @media screen {
      display: none;
    }

    &--item {
      margin-bottom: 2rem;
    }

    .process-item--title {
      font-size: 1rem;
      margin: 1rem 0;
    }

    .process-item--number {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
}
