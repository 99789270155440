.auto-grid {
  width: 100%;
  list-style: none;
  padding: 0;


  &.-theme-grid {
    display: grid;
    column-gap: $spacing-lg;
    row-gap: $spacing-lg;
  }

  @media print {

    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    &__item {
      flex: 1 32%;
    }
  }
}