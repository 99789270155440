.proposal-data {
  @include font-body;

  &--label,
  &--value {
    display: inline-block;
  }

  &--label {
    margin-right: $spacing-xs;

    &:after {
      content: ':';
    }
  }

  &--value {
    &-text {
      margin-right: $spacing-sm;
    }

    a {
      @include underline-animation-fill('span');
      text-decoration: none;
      color: $primary-grey3;

      &:not(:last-child):after {
        content: ', ';
      }
    }
  }

  &--item {
    margin-bottom: 14px;

    @include screen-md {
      padding-right: $spacing-md;
    }
  }
}
