.tabs {
  margin: $spacing-xxl 0;

  &--content {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-xxl;

    @include screen-lg {
      margin-top: $spacing-xxl;
    }
  }

  &--content-tab {
    &:focus {
      outline: none !important; // Fixes bug where tab-content gets focus-outline when using anchor-hash
    }

    &.-inactive {
      display: none;

      @media print {
        display: block;
      }
    }
  }
}

.no-tabs {
  margin-top: $spacing10;
}