.process-carousel {
  position: relative;

  & .carousel-items-wrapper {
    position: absolute;
    width: 100%;
  }

  &__button {
    position: absolute;
    height: 40px;
    width: 40px;
    border: 1px solid $primary-grey3;
    padding: 0;
    z-index: 2;

    &:hover {
      border: 1px solid $primary-blue;
      border-radius: 0;

      & > .icon {
        path {
          stroke: $primary-blue;
        }
      }
    }

    & > .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 8px;

      path {
        stroke: $primary-grey3;
      }
    }

    &[disabled] {
      border: 1px solid $background-grey3;
      & > .icon {
        path {
          stroke: #828282;
        }
      }
    }
  }
  &__button-prev {
    transform: translateY(-50%) scaleX(-1);
    right: 55px;
  }

  &__button-next {
    transform: translateY(-50%);
    right: 0;
  }

  &--item {
    padding: $spacing6 1.5rem 0.5rem 1.5rem;
    height: calc(100% - 0.5rem);
  }
}
