.cta-link {
  display: block;
  padding: $box-padding;
  padding-bottom: calc(#{$box-padding} + #{$spacing-xs} + 3px);
  background-color: $background-color;
  text-align: center;
  text-decoration: none;

  span {
    @include font-size-xl;
    font-weight: bold;
    line-height: 1.8;
    color: white;
    text-align: center;
    padding-bottom: $spacing-xs;
    border-bottom: 3px solid $focus-color;
  }

  img {
    margin: 0 auto;
  }
}
