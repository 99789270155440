.filters {
  position: relative; //Needed for backgroud-hover-effect
  display: flex;
  flex-direction: column;

  &--mobile {
    &-header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      font-size: convert-to-rem(24px);
      width: 100%;
      background: white;
      padding: $spacing-lg;
      z-index: 1;
    }

    &-title {
      @include font-headline3;
      text-align: left;
      text-transform: uppercase;
      flex: 1;
    }

    &-close {
      @include remove-button-styles;
    }

    &-close-text {
      @include visually-hidden;
    }
  }

  &--filters {
    @include screen-lg-max {
      @include font-large;
      flex: 1;
      padding: $spacing-sm $spacing-lg;
    }
  }

  &--deselect-button {
    @include remove-button-styles;
    @include font-body;
    text-align: left;
    margin: $spacing-lg 0 0;
    padding: $spacing-lg 0;
    width: 100%;

    @include screen-lg {
      display: none;
    }
  }

  &--title {
    @include font-large-subtitle;
    text-transform: uppercase;
    margin-top: $spacing-lg;
  }

  &--checkbox {
    margin-top: $spacing-lg;

    @include screen-lg {
      margin-top: $spacing-xl;
    }
  }

  &--range {
    margin-top: $spacing-lg;
  }

  &--show-results {
    position: sticky;
    bottom: 0;
    background: white;
    padding: $spacing-lg;
    z-index: 1;
  }
}
