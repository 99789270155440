.date-card-status {
  @include font-size-xs;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: $spacing-md;
  grid-area: status;

  &.-align-center {
    justify-content: center;
  }

  @include screen-md {
    text-align: center;
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  &.-result-is-published {
    &:before {
      background: $label-color;
    }
  }

  &.-is-completed {
    &:before {
      background: $element-color--dark;
    }
  }

  &.-is-active {
    &:before {
      background-color: $green;
    }
  }

  &.-is-planned {
    &:before {
      background-color: white;
      border: 2px solid $element-color--light;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: sub;
    height: 15px;
    width: 15px;
    min-width: 15px;
    border-radius: 50%;
    background-color: $red;
    margin-right: $spacing-sm;
  }
}
