.accordion-list-block {

  &__title {
    @include fluid-type(24px, 30px);
    text-align: left;
    line-height: 37.5px;
    font-weight: 500;
    padding-bottom: 1rem;
    margin-top: 0px;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $divider-grey2;
  }

  &__list {
    @include remove-list-styles;
    margin: 0;
  }
}