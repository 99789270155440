.pagination {
  margin-top: $spacing-xl;

  h2 {
    @include visually-hidden;
  }

  ul {
    @include remove-list-styles;
  }

  li {
    @include line-separator;
    display: inline-block;
    padding: 0 $spacing-md;

    &:first-child {
      padding-left: 0;
    }
  }

  &--link {
    &.-is-current {
      font-weight: bold;
      color: black;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
