.proposal-page {
  margin-top: $spacing-xxl;

  &--heading {
    display: flex;
    align-items: center;
    margin-top: unset;
    margin-bottom: $spacing10;
    @include font-headline1;

    @include screen-sm {
      margin-bottom: $spacing11;
    }
  }

  &--icon {
    stroke: $primary-grey3;
    margin-right: $spacing-lg;
  }

  &__status-container {
    margin-bottom: 1rem;
    display: flex;

    div.status:not(:first-of-type) {
      margin-left: 2em;
    }
  }

  &--metadata {
    @include screen-md {
      display: flex;
    }
  }

  &--metadata-column {
    @include screen-md {
      width: 50%;
    }
  }

  &--contact {
    > span {
      @include font-body;

      &:after {
        content: ':';
        margin-right: $spacing-sm;
      }
    }
  }

  &--share {
    @include font-size-sm;
    border-top: 1px solid $element-color--light;
    margin: $spacing-xl 0 0;
    padding-top: $spacing-lg;
  }

  &--header {
    padding: $spacing-lg 0 $spacing-lg;

    &-actions {
      display: flex;
      margin-top: $spacing-lg;

      .button {
        height: 40px;
      }
    }
  }

  &--apply-button {
    margin-right: 1rem;
  }

  &--message-container {
    margin: $spacing4 auto !important;

    @include screen-sm {
      margin: $spacing6 auto !important;
    }
  }

  &--description {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }

  &--description-ingress {
    @include font-size-lg;
    line-height: 1.5;
    margin-bottom: $spacing-lg;
  }

  &--download {
    .options-modal__button {
      @extend .-theme-secondary;
    }
  }
}
