.link-with-text-list {
  & > div {

    @media print {
      word-break: break-word;
    }
    
    &:not(:last-child) {
      margin-bottom: $spacing4;
    }
  }

  @include screen-md {
    display: flex;

    & > div {
      flex: 1;

      &:not(:last-child) {
        margin-right: $spacing4;
      }
    }
  }
}
