.vacancies {
  max-width: 888px;
  margin: 0 auto;

  &--title {
    @include font-size-xxl;
    width: 100%;
    border-bottom: 1px solid $element-color--light;
    padding-bottom: $spacing-md;
    margin-top: $spacing-xl;
    margin-bottom: $spacing5;
  }

  &--cta {
    margin-top: $spacing5;

    @include screen-sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      @include font-size-body;
      margin: unset;

      @include screen-sm {
        width: 66%;
      }
    }
  }
}
