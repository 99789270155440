.random-shapes {
  height: 10rem;
  padding-top: 2rem;

  &--parallax-scrolling {
    position: absolute;
    width: 100%;
    left: 0;
  }

  &--shapes {
    display: flex;
    position: absolute;
    left: 0;
    transform-origin: left;
    z-index: 1;

    @include screen-md-max {
      transform: scale(0.7);
    }

    &-shape {
      &.-circle {
        width: 75px;
        height: 75px;

        margin-left: 2rem;
      }
    }

    &.-right {
      left: 100%;

      &.-column {
        .shape {
          transform: translateX(-100%);
        }
      }

      &-horizontal {
        flex-direction: row-reverse;
        transform: translateX(-100%);

        .longH {
          transform: scale(-1);
        }
        .-circle  {
          margin: 0 2rem 0 0;
          transform: unset;
        }
      }
    }

    &.-column {
      width: 100%;
      flex-direction: column-reverse;

      .snakeV {
        transform: scaleX(-1);
      }

      .-circle {
        margin-left: 0;
        margin-bottom: 2rem;
      }
    }

    &.-reverse {
      &.-column {
        flex-direction: column;

        .-circle {
          margin: 2rem 0 0;
        }
      }
    }
  }
}
