.components {
  display: flex;

  &--list {
    padding: 0;
    margin-top: 0;
    margin-right: 2rem;
    height: 100%;

    @include screen-md-max {
      display: none;
    }

    &-sort {
      h2 {
        width: 100%;
        margin-bottom: 0;
        padding-left: 1rem;
      }

      &-buttons {
        display: flex;
        align-items: center;

        &-button {
          font-weight: 600;
          margin: 0;
          padding: 0 1rem;
          cursor: pointer;

          &:nth-child(2) {
            color: $element-color--darker;
          }

          &:nth-child(3) {
            min-width: 8rem;
          }
        }
      }
    }

    &-item {
      list-style-type: none;
      border-bottom: 1px solid $element-color--dark;

      &-button {
        display: flex;
        padding: 0.5rem 1rem;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
        align-items: center;
      }

      &-profile-tag {
        font-weight: 600;
        color: $info-red;
        margin-right: 1rem;
        width: 3rem;
      }

      &-name {
      }

      &-epi-name {
        margin-left: 1rem;
        color: $element-color--darker;
      }
    }
  }

  &--row {
    display: flex;
    width: 100%;

    &.-theme-dark {
      background: $primary-grey3;
      color: white;
    }
  }

  &--component {
    width: 100%;

    &-title {
      width: 100%;
      @include font-headline1;
    }

    &-description {
      @include font-headline3;
      margin-top: 0;
    }

    &-version-title {
      @include font-headline2;
      width: 100%;
    }
  }

  .content-area-item > .event-image {
    position: relative;
    width: 12rem;
    height: 12rem;
  }
}
