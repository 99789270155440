.newsletter-registration-page {
  &--title {
    margin-bottom: $spacing-lg;
  }

  &--email-title {
    margin-bottom: $spacing-md;
  }

  &--submit {
    margin-top: $spacing-xl;
  }

  &--footer {
    margin-top: $spacing-xxl;
  }

  &--group {
    margin-bottom: $spacing7;

    .text-input {
      margin-top: $spacing4;
    }
  }

  &--message {
    margin-top: $spacing4;
  }
}