.page-links-block {
  background-color: $element-color--lightest;
  overflow: hidden;

  &--header {
    position: relative;
    margin-bottom: $box-padding;
    padding-bottom: $box-padding;

    &:before {
      @include orange-bar;
    }

    h2 {
      @include font-size-xxl;
      margin: 0;
    }

    > p {
      @include font-size-lg;
      margin-top: $spacing-lg;
      margin-bottom: 0;
    }
  }

  &--content {
    padding: $spacing-xl 0;
  }

  &--links {
    @include underline;
    margin-bottom: $box-padding;
    &:before {
      left: $spacing-md;
      right: $spacing-md;
    }
  }
}
