@mixin link-list {
  ul {
    @include remove-list-styles;
    margin: 0;
    padding: 1rem 0;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.footer {
  background-color: $primary-grey3;
  margin-top: $spacing8;
  padding-top: $spacing8;
  padding-bottom: $spacing7;
  color: white;

  @media print {
    display: none;
  }

  &--content {
  }

  &--about {
    margin-bottom: $spacing5;

    @include screen-md {
      width: 50%;
    }
  }
  &--newsletter-link {
    margin-bottom: $spacing7;
  }

  &--top {
    margin-bottom: $spacing7;

    @include screen-md {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing11;
    }

    & > div {
      flex: 1;
    }

    &-right-mobile {
      .footer--link-list {
        @include link-list;
      }

      @include screen-md {
        display: none;
      }
    }

    &-right-desktop {
      display: none;
      @include screen-md {
        display: flex;
        justify-content: space-between;

        .footer--link-list {
          @include link-list;
          flex: 1;

          h2 {
            margin: 0 0 0.8rem 0;
            @include font-headline2;
          }
        }
      }
    }
  }

  &--contact-info {
    .html-string {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $border-grey;
      padding-bottom: $spacing5;

      @include screen-md {
        justify-content: space-between;
        flex-direction: row;
        padding: $spacing2 0;
        border-top: 1px solid $border-grey;

        & > p {
          flex: 1;

          &:last-child,
          &:first-child {
            margin-top: 1rem !important;
            margin-bottom: 1rem !important;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &--bottom {
    display: flex;
    flex-direction: column-reverse;

    @include screen-md {
      justify-content: space-between;
      flex-direction: row;
      padding: $spacing2 0;
    }

    &-some-links {
      width: 25%;

      @include screen-md-max {
        margin-left: 0 !important;
        padding: $spacing7 0 $spacing3;
      }

      a {
        &:first-child {
          margin-left: 0rem !important;
        }

        &:last-child {
          margin-right: 0rem !important;
        }
      }
    }
  }
}
