.social-media-link-list {
  display: flex;
  margin-left: auto;

  &.-theme-white {
    .social-media-link-list--button {
      color: white;
    }
  }

  &--button {
    color: $link-color;
    margin: 0 $spacing-xs;

    &:last-of-type {
      margin-right: 0;
    }

    span {
      @include visually-hidden;
    }
  }
}
