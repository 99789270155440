.dates-more {
  position: absolute;
  &.-is-open {
    z-index: 10;
  }

  &.-theme-medium {
    min-height: 71px; // For empty DatesMore
    .dates-more--button {
      padding: $spacing-lg $spacing-md;
    }
  }

  &.-theme-small {
    .dates-more--button {
      padding: 0.7rem 1rem;
    }

    .dates-more--title {
      font-size: convert-to-rem(14px);
    }

    .dates-more--icon {
      width: 16px;
    }

    .dates-more-link-list {
      padding: 0 0.7rem 1rem;
      font-size: convert-to-rem(14px);

      &-item {
        margin-bottom: 0.3rem;
      }
    }
  }

  &--button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    color: $primary-grey3;
    border: 1px solid $primary-grey3;
    transition: all 0.3s;
    padding-left: 16px;
    width: 100%;

    &:hover {
      color: $primary-blue;
      border-radius: 0;
      border-color: $primary-blue;
    }

    &.-is-open {
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      padding-left: 150px;
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 100px !important;
      transition: all 0.3s;
      border-color: $primary-grey3;
    }
  }

  &--title {
    @include font-tiny;
    margin: 0 $spacing2 0 0;
  }

  &--icon {
    transition: transform 0.3s;
    &.-is-open {
      transform: rotateZ(180deg);
    }
  }

  &--collapse {
    position: relative;
  }
}
