.proposal-group {
  margin-top: $spacing-lg;
  margin-bottom: $spacing6;

  @include screen-sm {
    margin-bottom: $spacing8;
  }

  &--header {
    background-color: $background-grey2;
    padding: $spacing5;
    color: $primary-grey3;

    h2 {
      @include font-large-subtitle;
      margin: 0;
      color: $primary-grey3;
      flex: 1;
    }

    > p {
      margin: 0;
    }

    &--button {
      color: $primary-grey3;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      color: $primary-grey3;
      padding: 0;
      height: fit-content;
      text-align: left;
    }

    &--icon {
      @include accordion-icon;
      margin: $spacing-sm 0 $spacing-sm $spacing-sm;
    }
  }

  &--content {
    overflow: hidden;
  }

  &--text {
    margin-top: $spacing-md;
    a {
      color: $primary-grey3;
      &:hover {
        color: $primary-grey3;
      }
    }
  }

  &--toggle {
    @include font-size-sm;
    margin-top: $spacing-lg;
  }
}
