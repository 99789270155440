.top-filter {
  display: flex;
  flex-wrap: wrap;

  p {
    margin: 0;
    margin-right: $spacing-sm;
  }

  &--input {
    @include line-separator;

    margin-right: 10px;
    padding-right: 10px;
  }
}
