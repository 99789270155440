.modal {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  transition: visibility 0s 0.5s;

  &.is-visible {
    transition-delay: 0s;
    visibility: visible;
  }
}

.modal-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
