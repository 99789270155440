.frontpage-header {
  display: flex;
  flex-direction: column-reverse;
  background: $background-grey2;
  min-height: 500px;

  @include screen-md {
    flex-direction: row;
  }

  &.-theme-yellow {
    .frontpage-header--image::after {
      background: $primary-yellow;
    }
  }

  &.-theme-red {
    background: #e42225;
    color: white;

    .frontpage-header--image::after {
      width: 0;
    }
  }

  //np styles
  &.-theme-esaki {
    background: $np-esaki;
  }

  &.-theme-marconi {
    background: $np-marconi;
  }

  &.-theme-alfven {
    background: $np-alfven;
  }

  &.-theme-esaki,
  &.-theme-marconi,
  &.-theme-alfven {
    .frontpage-header--image::after {
      background: $np-curie;
    }
  }

  //end np styles

  &--image {
    position: relative;
    height: 250px;

    &::after {
      @include vertical-bar;
    }

    @include screen-md {
      width: 50%;
      min-height: 100%;
      height: auto;
    }
  }

  &--prioritised-content {
    flex: 1;
    padding: $spacing7;
    padding-left: 0px; // remove left padding to align with logo

    @include screen-md-max {
      padding: 0px $spacing4 $spacing5 $spacing4;
    }

    @include screen-md {
      display: flex;
      flex-direction: column;
      justify-content: end;
      max-width: calc(min(670px, 46vw)); //align with logo
      width: 50%;
      margin-left: auto;
    }

    @include screen-lg {
      padding-right: $spacing7;
    }
  }

  &--title {
    margin: 0 0 $spacing-md;
    @include font-headline1;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-wrap;

    @include screen-md-max {
      margin-top: $spacing9;
    }
  }

  &--description {
    @include font-large;
  }

  &--link {
    margin: $spacing-md $spacing-md 0 0;
  }
}
