.chapter-navigation-list {
  position: relative;


  li {
    margin-left: 40px;
    padding: 9px 0;
  }

  li.-has-button {
    margin-left: 0px;
  }

  &--header {
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
  }

  &--toggle {
    right: 0;
    display: flex;
    justify-content: center;
    line-height: 1;
    padding: 0;
    margin-right: 16px;
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
    color: $primary-blue;



    &.-is-active {
      color: white;
      transform: rotate(-180deg);
      background-color: $primary-grey3;
    }

    &:hover {
      background-color: $primary-blue;
      color: white;
    }

    svg {
      width: 12px;
    }

    transition-property: background-color;


  }
}