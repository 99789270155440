.volume-slider {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 30px;
  height: 100px;
  border-radius: 50px;
  background: $element-color--lightest;
  transform: translateX(-50%);

  &--track {
    position: relative;
    margin: 0.6rem 0.7rem;
    height: calc(100% - 1.2rem);
    background: $text-color;
  }

  &--marker {
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.8rem;
    height: 0.8rem;
    background: $text-color;
  }
}
