.person-block {
  &.-has-image {
    display: grid;
    grid-template-areas: 'image name' 'image job-title' 'person-block-text person-block-text';
    grid-template-columns: 80px auto;
    grid-template-rows: max-content auto auto;
    column-gap: $spacing-md;

    @include screen-md {
      grid-template-areas: 'image name' 'image job-title' 'image person-block-text';
      grid-template-columns: 25% auto;
      grid-template-rows: max-content max-content auto;
      column-gap: $spacing-xl;
    }
  }

  &:not(:first-child) {
    margin-top: $spacing-xl;
  }

  h3 {
    @include font-size-lg;
    grid-area: name;
    margin: 0;
  }

  &--image {
    grid-area: image;
    position: relative;
    width: 100%;

    img {
      width: 100%;
      border-radius: $radius2;
    }
  }

  &--content {
    padding-left: $spacing-lg;

    @include screen-md {
      padding-left: $spacing-xl;
    }
  }

  &--job-title {
    grid-area: job-title;
    margin: $spacing-md 0 0 0;

    span:not(:first-child):before {
      content: ', ';
    }

    span:empty:before,
    span:empty + span:before {
      content: none;
    }
  }

  &--text {
    grid-area: person-block-text;
    margin-bottom: 0;

    @include screen-md {
      margin-top: $spacing-lg;
    }
  }
}
