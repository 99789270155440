// From the design system
$primary-yellow: #d6e82b;
$primary-yellow-green: #a9ad93;
$primary-blue: #5c5bee;
$primary-light-blue: #c8c7ff;
$primary-grey1: #f1f1f1;
$primary-grey2: #5e5e5e;
$primary-grey3: #1f1f1f;

$curie: #FDBC00;

$text-color: $primary-grey3;
$text-white: #ffffff;
$text-grey: #5e5e5e;
$text-disabled: #D5D5D5;

$background-grey1: #ffffff;
$background-grey2: #f4f4f4;
$background-grey3: #dddddd;
$background-grey4: $primary-grey2;
$background-grey5: #303030;
$background-grey6: $primary-grey3;
$background-light-blue: #e6e6ff;
$background-yellow-green: $primary-yellow-green;

$accent-blue: $primary-blue;
$accent-yellow: $primary-yellow;
$accent-light-blue: #7f84ff;

$info-red: #f48f8f;
$info-yellow: #ffd87d;
$info-blue: #e0f8fe;
$error-red: #b2292a;

$np-esaki: #F2FCC0;
$np-marconi: #A7C7FF;
$np-alfven: #FEF1F1;
$np-curie: #FDBC00;


// Other
$theme-portfolio: #e6e6ff;
$border-grey: #ffffff29;
$tags-background: #f2f2f2;
$link-color: #00338d;

$divider-grey1: #e5e5e5;
$divider-grey2: #0000001f;
$divider-grey3: #00000052;
$divider-grey4: #c9c9c9;

$accordion-background: #f4f4f4;

$background4: #b0b398; // Name taken from Figma
$background5: #d4d6c9; // Name taken from Figma
$disabled: #c1c1c1;