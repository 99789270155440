.portfolio-header {
  $self: &;
  background: $theme-portfolio;

  &.-page-header {
    #{$self}--content {
      min-height: 420px;
      flex-direction: column-reverse;
    }

    #{$self}--left {
      padding-top: 0;

      @include screen-lg {
        margin-left: auto;
        padding: $spacing7 0;
      }
    }

    #{$self}--image-padding {
      padding: $spacing7 0 $spacing7 0;

      .image {
        max-height: calc(300px - #{$spacing7} * 2);
        margin: auto;

        @include screen-lg {
          margin: auto 0 auto auto;
        }

        @include screen-sm {
          max-height: calc(420px - #{$spacing7} * 2);
        }
      }
    }

    #{$self}--page-header-title {
      @include font-headline1;
    }

    #{$self}--description {
      @include font-lead;
    }
  }

  &--content {
    min-height: 326px;
    display: flex;
    flex-direction: column;

    @include screen-lg {
      flex-direction: row !important;
    }
  }

  &--image {
    position: relative;
    height: 100%;
    width: 100%;

    @include screen-sm {
      width: 90%;
      margin: auto;
    }

    @include screen-lg {
      width: auto;
      margin: 0 0 0 auto;
    }

    &-padding {
      width: 50%;
      padding: $spacing7 0 $spacing7 0;
      margin: auto;

      @include screen-sm {
        margin: auto;
      }

      @include screen-lg {
        margin: 0 0 0 auto;
        padding: $spacing7 $spacing7 $spacing7 0;
      }
    }

    .image {
      max-height: calc(300px - #{$spacing7} * 2);
      margin: auto;

      @include screen-lg {
        margin: auto 0 auto auto;
      }

      @include screen-sm {
        max-height: calc(326px - #{$spacing7} * 2);
      }
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: $spacing4;
    padding-top: $spacing5;

    @include screen-md {
      padding-right: 4rem;
    }

    @include screen-lg {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 40px;
    }
    @include screen-xl {
      padding-right: 2rem;
    }
  }

  &--title {
    @include fluid-type(30px, 36px);
    font-weight: 500;
    margin: 0;
    margin-bottom: $spacing2;

    @include screen-lg {
      margin-bottom: $spacing4;
    }
  }

  &--description {
    @include font-large;
    margin-top: 0;
    margin-bottom: $spacing6;

    @include screen-lg {
      letter-spacing: -0.4px;
    }
  }

  &--link {
    margin-right: $spacing2;
  }
}
