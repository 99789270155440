.image-with-link {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $background-grey2;
  text-decoration: none;
  border-radius: 12px 12px 12px 12px;

  @media print {
    border: 1px solid;
  }

  &--image {
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
    background-color: $element-color--lightest;
  }

  &--image-inner {
    aspect-ratio: 3/2;
    position: relative;
    width: 100%;
    padding-top: 65%;
    transition: transform $ease-out 0.3s;

    @media print {
      padding-top: 0;
    }

    .image-with-link:hover &.-hover-effect-active {
      transform: scale(1.05);
    }

    &.--absolute-height {
      padding-top: 11.5rem;
    }
  }

  &--description {
    padding: $spacing-lg $spacing-lg;
    border-radius: 0px 0px 12px 12px;

    &.dark-version {
      background-color: $primary-grey3;

      > .image-with-link--title,
      .image-with-link--text {
        color: $primary-grey1;
      }
    }
  }

  &--title {
    @include font-large-headline;
    color: $primary-grey3;

    .image-with-link:hover & {
      text-decoration: underline;
    }

    width: 100%;
    max-width: fit-content;

    &.dark-version {
      color: $primary-grey1;
    }
  }

  &--text {
    @include font-small;
    color: $primary-grey3;
    max-width: fit-content;
    margin: 0;
    width: 100%;

    &.dark-version {
      color: $primary-grey1;
    }
  }
}

// from card.scss
// &--title {
//   @include font-large-headline;
//   margin: $spacing2 0 $spacing1;
// }

// &--text {
//   @include font-small;
//   margin-top: 0;
// }

// .fluid-image--image {
//   transition: transform $transition;
// }
