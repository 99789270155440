.steps-menu {
  $pageHeader: 148px;
  $npMethodHeader: 373px;
  $npMethodHeaderMobile: 220px;
  position: relative;

  height: calc(100vh - (#{$npMethodHeaderMobile} + #{$pageHeader}));

  @include screen-md-with-height {
    height: calc(100vh - (#{$npMethodHeader} + #{$pageHeader}));
  }

  &.-vertical {
    width: 23rem;
    max-width: 100%;
    max-height: 550px;

    @include screen-md-max {
      min-height: 500px;
      margin: auto;
    }
  }
}
