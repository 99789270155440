.search-result {
  display: block;

  @include screen-md {
    display: flex;
    flex-direction: column;
  }

  &.-theme-sidebar {
    margin-top: 0;
  }

  &__image-container {
    position: relative;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  &__icon {
    margin-right: $spacing-md;
    margin-top: -4px;
    color: $text-color;
  }

  &__title {
    @include font-body-headline;
    color: $text-color;

    &.-theme-sidebar {
      @include font-size-lg;
      margin-top: 0;
    }

    &--has-descriptive-title {
      margin-top: $spacing-sm;
    }
  }

  &__descriptive-title {
    @include font-size-sm;
    margin-top: 1.245em;
  }

  &__status {
    margin-right: $spacing-xl;

    > * {
      margin-bottom: $spacing-md;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__metadata {
    > * {
      margin-top: 0;
    }
  }

  :not(:last-child) > & {
    padding-bottom: $spacing-lg;
    border-bottom: 1px solid $element-color--light;
  }

  &.-theme-sidebar {
    padding-bottom: 0;
  }

  &.-theme-no-border,
  &.-theme-sidebar {
    border: none;
  }

  &.-theme-dark-text {
    p {
      color: $text-color;
    }
  }

  h3 {
    @include font-size-xl;

    font-weight: normal;
    margin: $spacing-sm 0;
  }

  p {
    color: $element-color--darker;
    margin-bottom: 0;
  }

  dl {
    color: $text-color;
  }

  mark {
    background-color: $yellow;
    font-weight: 600;
  }

  &--tags {
    @include remove-list-styles;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      margin-right: $spacing-xs;
      margin-top: $spacing-xs;
    }
  }

  &--tag {
    @include font-size-xs;
    border-left: 4px solid $element-color--light;
    color: white;
    display: inline-block;
    padding: 5px 10px;
    line-height: 1;
    text-decoration: none;
    background-color: $background-color;
  }

  &--link {
    display: block;
  }
}
