.range-slider {
  border: none;
  padding: $spacing-lg;
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
  background-color: $element-color--lightest;
  user-select: none;

  legend {
    display: table;
    float: left;
    margin: 0;
    margin-bottom: $spacing-lg;
    padding: 0;
    width: 100%;
  }

  &--inputs {
    display: flex;
    justify-content: space-between;
    clear: both;
  }

  &--slider {
    position: relative;
    height: 15px;
    background-color: white;
    border: 1px solid $link-color;
    border-radius: 10px;
    margin-top: $spacing-lg;

    &:before {
      content: '';
      position: absolute;
      right: 3px;
      left: 3px;
      top: 3px;
      bottom: 3px;
      background-color: $element-color--lighter;
      border-radius: 4px;
    }
  }

  &--slider-inner {
    position: relative;
    height: 100%;
    margin-left: $spacing-sm;
    margin-right: $spacing-sm;
  }

  &--active-region {
    position: absolute;
    top: 3px;
    bottom: 3px;
    background-color: $focus-color;
  }

  &--knob {
    $knob-size: 20px;
    position: absolute;
    top: 50%;
    height: $knob-size;
    width: $knob-size;
    margin-top: -10px;
    background-color: white;
    border: 2px solid $focus-color;
    border-radius: 20px;

    &.-from {
      // Move knob slightly to the left to make it cover the border of the slider track
      margin-left: -($knob-size / 2) - 1px;
    }

    &.-to {
      // Move knob slightly to the right to make it cover the border of the slider track
      margin-left: -($knob-size / 2) + 1px;
    }

    &:before {
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
    }
  }

  &--submit {
    margin-top: $spacing-lg;
  }
}
