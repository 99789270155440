.icon {
  display: block;
  fill: transparent;
  stroke: currentColor;

  &.-use-fill-color {
    fill: currentColor;
    stroke: transparent;
  }

}