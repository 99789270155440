.html-string {
  :root & {
    // When rendered in content areas, floating is disabled by default. This enables floating so that texts can be rendered beside floated content.
    clear: none;
  }

  @media print {
    p {
      font-size: 0.7rem;
    }
  }

  &.-theme-narrow {
    max-width: $content-width-sm;
    margin-left: auto;
    margin-right: auto;
  }

  &.-theme-white {
    color: white;

    a,
    a:hover {
      color: inherit;
    }
  }

  a {
    word-break: break-word;
    color: $link-color;

    &:hover {
      color: black;
    }
  }

  h1 {
    //This should never be used
    color: red;
  }
  h2 {
    @include font-headline2;

    @media print {
      font-size: 1.1rem;
    }
  }
  h3 {
    @include font-headline3;
  }
  h4 {
    @include font-large-subtitle;
  }
  h5 {
    @include font-body-headline;
  }
  h6 {
    @include font-body-subtitle;
  }

  caption {
    @include font-data($important: true);
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;

    & + p {
      margin-top: $spacing-xs;
    }
  }

  h2 {
    margin-top: $spacing-xl;

    & + p {
      margin-top: $spacing-sm;
    }
  }

  h5 {
    & + p {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: $spacing-lg;

    li {
      margin-top: $spacing-sm;
    }
  }

  ul,
  ol,
  dl {
    @include font-body;
  }

  kbd,
  code,
  pre,
  samp {
    @include font-data-large;
  }

  hr {
    border: none;
    border-bottom: 1px solid $element-color--light;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
  }

  dd + dt {
    margin-top: $spacing-md;
  }

  mark {
    padding: 0 $spacing-xs;
    border-radius: 4px;
  }

  &__table-wrapper {
    overflow-x: auto;
  }

  p {
    @include font-body;
  }
}
