.np-method {
  position: relative;
  padding-top: 1rem;
  z-index: 2;
  min-height: 100%;

  @include screen-lg-max {
    overflow-x: hidden;
    background: $np-method-background;
  }

  &--background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background: $np-method-background;
    z-index: -1;
  }

  &-path {
    color: $element-color--lighter !important;

    &.-mobile {
      @include screen-md {
        display: none;
      }
    }

    &.-desktop {
      @include screen-md-max {
        display: none;
      }
    }
  }

  &--header {
    display: flex;

    @include screen-md-with-height {
      padding-top: 5rem;
    }

    &-shapes {
      display: none;
      justify-content: space-between;
      margin-top: 0.8rem;

      & > .icon {
        margin-right: 1rem;
      }

      @include screen-sm {
        display: flex;
      }

      &--half-hidden {
        transform: translateX(-50%);
      }
    }

    &-content {
      margin-left: 1rem;
      max-width: 500px;
      font-weight: 600;
      z-index: 1;
    }

    &-title {
      margin-top: 0;
      font-family: $font-family-np;
      @include fluid-type(40px, 72px);

      @include screen-md-with-height-max {
        font-size: 40px;
        margin-bottom: 0;
      }
    }

    &-ingress {
      font-weight: 400;
      line-height: 1.3;
      @include fluid-type($font-size-np, 30px);

      @include screen-md-with-height-max {
        font-size: $font-size-np;
        margin: $spacing-sm 0 $spacing-md;
      }
    }

    &-link {
      color: $label-color !important;
    }

    &-link-icon {
      fill: $label-color;
      color: white;
    }

    &-snake {
      display: none;
      margin: auto -2.8rem 0rem auto;
      width: 20rem;
      height: 100%;

      @include screen-lg {
        display: block;
      }
    }
  }
}
