.video-block {
  padding-top: 3rem;
  margin-top: -3rem;

  @include screen-lg {
    display: flex;
    align-items: center;
  }

  &--video {
    position: relative;
    flex: 1;
    max-width: 480px;
    margin: 0 auto;

    @include screen-md {
      margin-top: 1rem;
    }

    @include screen-lg {
      margin: 0;
    }

    & > .youtube-video {
      padding-bottom: 178%;
      height: 100%;

      &:before {
        content: unset;
      }
    }
  }

  &--content {
    flex: 1;
    padding: $spacing-lg;

    @include screen-lg {
      padding: 0 0 0 $spacing-xxl;
    }
  }

  &--title {
    font-size: 32px;
    font-family: $font-family-np;

    @include screen-md {
      font-size: 52px;
    }

    &-upper {
      display: block;
      font-size: 20px;
      margin-bottom: $spacing-sm;

      @include screen-md {
        font-size: 32px;
      }
    }
  }

  &--text {
    // font-size: 18px;

    @include screen-md {
      //font-size: $font-size-np;
    }
  }

  &--button {
    margin-top: 2rem;
    padding-top: $spacing-md !important;
    padding-bottom: $spacing-md !important;
  }
}
