.filter-layout {
  &--header {
    margin-bottom: $spacing1;

    h1 {
      grid-area: filter-layout-title;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: $spacing-xl;
    }

    button {
      align-self: end;
      margin-bottom: $spacing-xs;
    }
  }

  &--top-line {
    grid-area: filters;
    &.bottom-border {
      border-bottom: 1px solid $element-color--light;
      padding-bottom: $spacing-sm;
    }
  }

  &--content {
    position: relative;
  }

  &--toggle {
    @include outline;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 30px;
    margin: $spacing-md 0;
    color: $text-color;
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;

    & > .icon {
      width: 30px;
      height: 28px;
    }

    @include screen-sm {
      margin-left: unset;
      flex-direction: row;
      & > .icon {
        margin-left: $spacing-sm !important;
      }
    }
    @include screen-lg {
      display: none !important;
    }
  }

  &--filters {
    margin-bottom: $spacing-xl;

    &.-is-desktop {
      display: none;

      @include screen-lg {
        display: block;
      }
    }

    &.-is-mobile {
      position: fixed;
      overflow: auto;
      top: 0;
      right: 0;
      margin-top: -1px;
      z-index: 101;
      width: 100%;
      height: 100vh;
      background-color: white;

      @include screen-lg {
        display: none;
      }

      &.before-enter {
        transform: translateX(100%);
      }

      &.entering {
        transform: none;
        transition: transform 0.5s $ease-out;
      }

      &.before-leave {
        transform: none;
      }

      &.leaving {
        transform: translateX(100%);
        transition: transform 0.5s $ease-out;
      }
    }
  }
}
