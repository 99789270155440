$spacing1: 8px;
$spacing2: 12px;
$spacing3: 16px;
$spacing4: 24px;
$spacing5: 32px;
$spacing6: 40px;
$spacing7: 48px;
$spacing8: 64px;
$spacing9: 80px;
$spacing10: 96px;
$spacing11: 120px;
$spacing12: 144px;

$radius1: 10px;
$radius2: 12px;
$radius3: 16px;

// Content width
$content-width-sm: 680px;
$content-width-md: 840px;
$content-width-l: 888px;
$content-width-lg: 1024px;
$content-width-xl: 1344px;

$transition: 0.3s ease-out;

//OLD
@import 'np-method-colors.scss';
// Font
$font-size-base: 16px;
$font-stack: 'Neue Montreal', sans-serif;
$font-family-np: 'Nunito';
$font-size-np: 24px;
// Animation
$ease-out: cubic-bezier(0.22, 0.57, 0.25, 1);
$ease-in: cubic-bezier(0.62, 0, 0.85, 0.68);
$ease-in-out: cubic-bezier(0.65, 0.09, 0.06, 1);

// Spacing
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 48px;
$spacing-xxl: 64px;
$spacing-xxxl: 128px;
/*
// Content width
$content-width-sm: 680px;
$content-width-md: 840px;
$content-width-lg: 1024px;
$content-width-xl: 1340px;
*/
$menu-width-lg: 475px;

$list-image: 150px;

// Border radius
$border-radius-sm: 12px;
$border-radius-md: 16px;
$border-radius-lg: 25px;

// Box padding
$box-padding: 32px;
$box-padding--medium: 24px;
$box-padding--small: 16px;

// Breakpoints
$breakpoint-xs: 330px;
$breakpoint-sm: 510px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-xxl: 1600px;

$breakpoint-xs-max: $breakpoint-xs - 1px;
$breakpoint-sm-max: $breakpoint-sm - 1px;
$breakpoint-md-max: $breakpoint-md - 1px;
$breakpoint-lg-max: $breakpoint-lg - 1px;
$breakpoint-xl-max: $breakpoint-xl - 1px;
$breakpoint-xxl-max: $breakpoint-xxl - 1px;

$np-block-padding: $spacing-xl $spacing-lg;