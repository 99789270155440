.image-tag {
  @include font-data;
  position: absolute;
  right: 0;
  margin: $spacing3;
  padding: 6px 8px;
  color: white;
  background: $primary-grey3;
  border-radius: 30px;
  z-index: 1;
}
