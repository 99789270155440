.number-block {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  padding: $box-padding;
  height: 100%;

  .has-action-link {
    margin-top: $spacing-md;
  }

  &--number-wrapper {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  &--number {
    @include font-display1;
    color: $primary-blue;
    margin: 0;
    margin-bottom: $spacing-md;
  }

  &--description {
    @include font-body-headline;
    font-weight: bold;
    margin: 0;
    margin-bottom: $spacing-md;
    min-width: 200px;
  }

  h2 {
    @include font-body;
    font-size: inherit;
    margin: 0;
  }

  p {
    margin: 0;
  }
}
