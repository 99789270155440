.portfolio-tiles {
  & .auto-grid {
    margin: 0;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  &--link {
    display: block;
  }

  &--title {
    color: inherit !important;
    transition: transform 0.3s;

    h2 {
      text-align: center;
      margin: 0;
      font-size: convert-to-rem(14px);
      line-height: 125%;
      font-weight: 600;

      @include screen-md {
        font-size: convert-to-rem(25px);
      }
    }
  }

  &--tile {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 14px 12px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @include screen-md {
      padding: 20px;
    }

    &:hover {
      .portfolio-tiles--link {
        transform: scale(1.1);
      }

      .icon {
        transform: scale(1.2);
      }
    }

    &-square {
      position: relative;
      padding-top: 100%;
      height: 0;
    }
  }
}
