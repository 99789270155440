.download-list {
  &.-theme-prominent {
    background: $background-light-blue;
  }

  &.-theme-grey {
    background: #f4f4f4;
  }

  &--heading {
    @include font-headline2;
    margin-bottom: $spacing2;
    margin-top: 0;
  }

  &--subheading {
    @include font-data-large;
    font-size: 14px !important;
    margin-top: $spacing5;
    border-bottom: 1px solid #00000052;
    padding-bottom: 1rem;
  }

  ul {
    @include remove-list-styles;
  }

  &--download-icon {
    position: relative;
    width: 32px;
    height: 32px;

    & > .icon {
      color: $primary-grey3;
    }

    .fluid-image--image {
      transition: background-position $transition;
    }
  }

  li {
    line-height: 1.3;

    &:not(:last-child) {
      border-bottom: 1px solid $divider-grey2;
    }

    &:hover,
    &:focus {
      .download-list--item-text-name {
        position: relative;

        &::after {
          transform: scaleX(1);
        }
      }

      .download-list--download-icon {
        & > .icon {
          color: $primary-blue;
        }

        .fluid-image--image {
          background-position: 0 32px;
        }
      }
    }

    a {
      padding: $spacing-md 10px;
    }
  }

  &--group {
    margin: $spacing-lg 0 $spacing-lg 0;
  }

  &--item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-icon {
      margin-top: -10px;
    }
    &-text {
      flex: 1;
      margin: 5px 0 0 $spacing4;

      &-name {
        @include font-body-subtitle;
        @include underline-animation('span');
        position: relative;
        margin-bottom: $spacing2;
        color: $text-color;
        width: fit-content;
      }

      &-size {
        @include font-data;
        color: $text-color;
      }
    }
  }

  &--required {
    @include font-data;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    & > .icon {
      margin-right: 1rem;
    }
  }

  &--download-all {
    & > .button {
      width: max-content;
      background: white;

      & > .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
