.rss-block{
    height: 100%;
    font-size: convert-to-rem(16px);
    padding: $box-padding;
    background: $background-grey2;

    h2{
      font-size: convert-to-rem(24px);
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing3;
      padding-bottom: 12px;
      border-bottom: 1px solid $divider-grey2;

}
}