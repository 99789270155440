.article-page {
  &--title--large {
    @include list-page-title;
  }

  &--ingress {
    @include font-size-lg;
    line-height: 1.5;

    @media print {
      font-size: 0.9rem;
    }
  }

  &--footer {
    margin-top: $spacing8;
  }

  @media print {
    &--sidebar {
      display: none;
    }
  }
}
