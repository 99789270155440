.timeline-horizontal-print {
  padding: 0 1rem;

  @media screen {
    display: none;
  }

  &--title,
  &--sub-title {
    display: inline-block;
  }

  &--title {
    &:after {
      content: ':';
      margin-right: $spacing-xs;
    }
  }
}

.timeline-horizontal {
  position: relative;
  color: black;
  padding: $spacing5 0 $spacing7 0;

  @include screen-sm {
    padding: $spacing7 0 $spacing10 0;
  }

  @media print {
    display: none;
  }

  &--content {
    position: relative;
    @include screen-md-max {
      width: 100%;
    }
  }

  &--track {
    margin: $spacing-xl 0 $spacing-lg;
    opacity: 0;
    transition: opacity 0.4s;

    .-is-mounted & {
      opacity: 1;
    }

    @include screen-md {
      position: relative;
      margin: $spacing-xl (-$spacing-lg) $spacing-lg;
    }

    @include screen-lg {
      margin: $spacing-xl #{-$spacing-xl} $spacing-lg;
    }

    .-theme-block & {
      margin: $spacing-xl 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 44px;
      height: 45px;

      @include screen-sm {
        top: 28px;
      }
    }

    &:before {
      left: 0;
      width: 40px;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.01)
      );
    }

    &:after {
      right: 0;
      width: 40px;
      background-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.01)
      );

      @include screen-md {
        width: 100px;
      }
    }
  }

  &--expand,
  &--next,
  &--previous {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: $background-color;
    padding: 0;

    svg {
      position: relative;
      z-index: 2;
    }
  }

  &--expand {
    position: relative;
    margin: 0 auto;
    transform: rotate(90deg);

    &.-is-active {
      transform: rotate(-90deg);
    }
  }

  &--next,
  &--previous {
    position: absolute;
    height: 40px;
    width: 40px;
    border: 1px solid $primary-grey3;
    padding: 0;
    z-index: 2;
    top: -25px;

    @include screen-sm {
      top: -48px;
    }

    &:hover {
      border: 1px solid $primary-blue;
      border-radius: 0;

      & > .icon {
        path {
          stroke: $primary-blue;
        }
      }
    }

    & > .icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 8px;

      path {
        stroke: $primary-grey3;
      }
    }

    &[disabled] {
      border: 1px solid $background-grey3;
      & > .icon {
        path {
          stroke: #828282;
        }
      }
    }
  }

  &--next {
    transform: translateY(-50%);
    right: 45px;
  }

  &--previous {
    transform: translateY(-50%) scaleX(-1);
    right: 100px;
  }
}
