.tag-list {
  @include remove-list-styles;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 12px;

  li {
    margin-right: $spacing-xs;
  }

  &__tag {
    @include font-size-xs;
    border-left: 4px solid $element-color--light;
    margin-bottom: 4px;
    color: white;
    display: inline-block;
    padding: 5px 10px;
    line-height: 1;
    text-decoration: none;
    background-color: $background-color;
  }
}
