.office-list-block {
  &--links {
    @include remove-list-styles;
    margin: 0;
    text-align: center;

    @include screen-sm {
      display: flex;
      justify-content: center;
    }

    li {
      margin: $spacing-md 0 0;

      @include screen-sm {
        @include line-separator;
        padding: 0 $spacing-md;

        &.-is-active {
          position: relative;

          &:before {
            $size: 20px;
            content: '';
            position: absolute;
            bottom: -$spacing-lg - ($size / 2);
            left: 50%;
            height: $size;
            width: $size;
            background-color: $background-color;
            transform: translateX(-50%) rotate(45deg);
          }
        }
      }
    }
  }


  //special handling for text-break accecibility concern
  .non-breakable-tab-columns {
    li {
      @media (max-width: 1190px) {
        margin-right: unset !important;
      }
    }

    a {
      white-space: nowrap;
      overflow: unset !important;
      text-overflow: unset !important;
    }
  }

  &--offices {
    margin-top: $spacing7;
  }
}