.tags {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing-md 0 0;

  &-item {
    @include font-data;
    margin-right: $spacing1;
    margin-top: $spacing1;
    background: $tags-background;
    border-radius: 30px;
    padding: 6px 8px;
  }
}
