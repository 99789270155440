.date-card {
  @include font-size-sm;
  border-bottom: 1px solid $element-color--light;
  padding: $spacing-lg 0;

  &.-theme-no-border {
    border: none !important;
  }

  &.-theme-no-padding {
    padding: 0;
  }

  &.-without-date {
    .date-card__grid {
      grid-template-columns: 0px 1fr;
      column-gap: 0px;
    }
  }

  &.-theme-sidebar {
    border-bottom: 1px solid $element-color--light;
    padding: $spacing-lg 0;

    .date-card__grid-primary {
      grid-template-areas: 'date date' 'date-card-title date-card-title' 'date-card-content date-card-content';
      grid-template-columns: 80px 1fr;
    }

    .date-card__grid-secondary {
      grid-template-areas: 'status status' 'tags tags';
      grid-template-columns: 80px 1fr;
    }

    .date-card--title {
      margin: 10px 0;
    }
    .date-card--heading {
      @include font-size-lg;
    }

    .date-card-dates {
      &.-light-text {
        padding: $spacing-md;
      }

      &.-is-planned {
        padding: $spacing-md;
      }

      &.-is-past-date {
        padding: $spacing-md;
      }

      &--bar {
        margin-top: 14px;

        &:before {
          @include orange-bar(auto, 0, 39px, 4px);

          @include screen-md-max {
            @include orange-bar(auto, 0, 24px, 3px);
          }
        }
      }

      &--date {
        display: flex;
        align-items: flex-end;
        line-height: inherit !important;
      }

      &--past-date,
      &--description-top {
        text-align: left;
      }

      &--day {
        line-height: 1;
      }
      &--day,
      &--month,
      &--year {
        margin-right: $spacing-xs;
        position: static;
      }

      &--international {
        display: block;
      }
    }

    .date-card-media-item {
      @include screen-md {
        margin-top: $spacing-md;
      }
    }

    .date-card-status {
      text-align: left;
      margin-top: $spacing-sm;
    }
  }

  &.-theme-vertical {
    padding: 0 !important;
    height: 100%;

    @include screen-md {
      background: $element-color--lightest;
    }

    .date-card__grid-primary {
      grid-template-areas: 'date' 'date-card-title' 'date-card-content';
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr;
      height: 100%;
    }

    .date-card--title {
      margin: 10px 0;
      font-size: convert-to-rem(20px);

      @include screen-md {
        margin: 32px $spacing-md 0;
      }
    }

    .date-card-media {
      margin-top: 1rem;
      flex: 1 0 auto;

      &-item {
        margin-top: auto;
      }
    }

    .date-card--content {
      display: flex;
      flex-direction: column;
      margin: 0 $spacing-md $spacing-md;

      @include screen-md-max {
        margin: 0;

        .metadata {
          margin: 0;
        }
      }
    }

    .date-card--heading {
      color: black;
      line-height: 1.2;
    }
    .date-card-dates--bar:before {
      position: relative !important;
      @include screen-md-max {
        margin-left: 0;
      }
    }

    @include screen-md {
      .date-card__grid-secondary {
        padding: 0 1rem 1rem;
        grid-template-areas: 'media status';
      }
    }
  }

  @include screen-md {
    padding: $box-padding 0;
    &.-theme-right-aligned-tags {
      display: flex;
      padding: $spacing-lg 0;

      .date-card__grid-primary {
        flex: 1;
        margin-right: 24px;
      }
      .date-card__grid-secondary {
        grid-template-areas: 'tags' 'status';
        grid-template-columns: 300px;
      }
      .date-card-tags {
        margin: 0;
        justify-self: end;
      }
    }
  }

  &__grid {
    display: grid;
    column-gap: $spacing-md;

    @include screen-md {
      column-gap: $spacing-lg;
    }

    &-primary {
      grid-template-areas: 'date date' 'date-card-title date-card-title' 'date-card-content date-card-content';
      grid-template-columns: 80px 1fr;

      & .date-card-dates {
        //Fixes bug: autoprefixer makes another overridden 'template-areas', genererate a 'row-span: 2' for 'date'-area that isn't overridden in IE
        -ms-grid-row-span: 1 !important;
      }

      @include screen-md {
        grid-template-areas: 'date date-card-title' 'date date-card-content';
        grid-template-columns: 170px 1fr;
        column-gap: $spacing-lg;
      }
    }

    &-secondary {
      grid-template-areas: 'status status' 'tags tags';
      grid-template-columns: 80px 1fr;

      @include screen-md {
        grid-template-areas: 'status media' 'status tags';
        grid-template-columns: 170px 1fr;
        column-gap: $spacing-lg;
      }
    }
  }

  &--name {
    padding: 1rem $spacing-lg;
    margin: 0;
    background: $timeline;
    color: white;

    @include screen-md {
      text-align: center;
      padding: 1rem 1rem;
    }
  }

  &--title {
    grid-area: date-card-title;
    margin: 10px 0;

    @include screen-md {
      margin: 0;
    }
  }

  &--content {
    grid-area: date-card-content;
  }

  &--text {
    margin: $spacing-sm 0 0;
  }

  &--message {
    margin-top: $spacing-md;
  }

  &--heading {
    display: inline;
    @include font-size-xl;
    margin: 0;
    font-weight: normal;
  }

  &--sub-heading {
    @include font-size-sm;
    margin: $spacing-xs 0 0;
    font-weight: bold;
  }
}
