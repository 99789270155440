.accordion-with-content-area-list {
  @include font-body;

  &--title {
    @include font-headline3;
  }

  &--tag-list {
    margin-bottom: $spacing-md;
    &-item {
      @include font-data;
      display: inline-block;
      margin-right: $spacing1;
      margin-top: $spacing1;
      background: $tags-background;
      border-radius: 30px;
      padding: 4px 6px;
    }
  }
}
