.link-lists {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.-single-column {
    flex-direction: column;

    .link-lists--column {
      margin-left: 0;
    }
  }

  @include screen-md {
    flex-direction: row;

    &--column {
      flex: 1;

      &:nth-child(2) {
        margin-left: $spacing4;
      }
    }
  }
}
