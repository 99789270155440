.accordion-with-content-area {
  $self: &;
  background: $accordion-background;

  @media print {
    // NOTE: Force visibility of collapsed content when printing
    > div {
      height: auto !important;
      overflow: visible !important;
      visibility: visible !important;
    }
  }

  &--anchor-target {
    padding: $spacing-sm 0;
  }

  &--header {
    background-color: white;
    border-radius: unset !important;
    margin: 0;

    &:hover {
      background-color: $background-light-blue;
    }

    &--disabled {
      color: $element-color;
      &:hover {
        background-color: $element-color--lightest;
      }
    }
  }

  &--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing5 !important;
    height: auto !important;

    // :root for extra specificity
    :root & h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  &--description-list {
    dd,
    dt {
      display: inline-block;
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin: 0;
      padding-right: $spacing-lg;
    }
  }

  &__title-container {
    display: flex;
  }

  &__title-icon {
    margin-right: 0.5rem;
    margin-top: -3px;
  }

  &__title {
    @include font-size-lg;
    text-align: left;
    line-height: 1.6;
  }

  &--icon {
    @include accordion-icon;
  }

  &--collapse {
    position: relative;

    @media screen {
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $accordion-background 90%
        );
        opacity: 0;
        transition: opacity $transition;
      }
    }
    &.-closed {
      visibility: visible !important;

      &::after {
        opacity: 1;
      }
    }

    &.-opened {
      &::after {
        content: none;
      }
    }
  }

  &--content {
    overflow: hidden;
    padding: $spacing1 $spacing3 $spacing3;

    @include screen-md {
      padding: $spacing1 5rem 2rem 2.5rem;
    }

    &-area > .content-area-item:last-child {
      margin-bottom: 0;
    }

    > p {
      font-style: italic;
    }
  }
}
