.proposal-shortcuts {
  padding-left: $spacing2;

  @include screen-lg {
    padding-left: $spacing5;
  }

  &.-closed {
    background: $background-grey2;
  }

  &--title {
    @include font-headline2;
    @include fluid-type(24px, 30px);
  }

  &--button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $divider-grey2;
    padding: $spacing5 0 !important;
    border-radius: 0;
    text-align: left;

    &-icon {
      @include accordion-icon;
      margin: 0 $spacing2 0 $spacing2;

      @include screen-lg {
        margin-right: spacing5;
      }
    }
  }

  &--grid {
    margin: 0;
    padding: $spacing3 0;

    &-item {
      display: flex;
      flex-direction: column;

      &-title {
        @include font-headline3;
        margin-top: 0;

        &:focus {
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  &--item {
    position: relative;
    flex: 1;

    &:hover {
      .fluid-image--image {
        transform: scale(1.05);
      }
    }

    &-link {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;
      color: $primary-grey3;

      &-image {
        aspect-ratio: 1.8;
        position: relative;

        .fluid-image--image {
          transition: transform $transition;
        }
      }

      &-text-area {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &-title {
        @include font-large-headline;
        margin: $spacing4 0 $spacing1;
      }

      &-text {
        margin: 0;
      }

      &-status {
        @include font-large-headline;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        margin-top: $spacing3;

        @include screen-md {
          margin-top: $spacing5;
        }
      }
    }

    &-dates-more {
      top: 0;
      right: 0;
      padding: $spacing3;
    }
  }
}
