.tab-content-section {
  margin-bottom: 3rem;

  &-title {
    @include font-headline2;
  }

  &--ingress {
    @include font-body;
  }

  &--text {
    h2 {
      // This is to override the various main headings of tab-sections with minimum specificity so more deeply nested headings can override it
      margin-top: 0;
      @include font-headline3;
    }
    h3 {
      @include font-body-headline;
      margin-bottom: 0.5em;
    }
    h4 {
      @include font-size-body;
      color: $primary-grey3;
      margin-bottom: 0.3em;
    }
    h5 {
      color: $primary-grey3;
    }

    ul {
      li {
        padding-left: $spacing-md;
      }
    }
    p {
      @include font-body;
    }
  }

  &--content,
  &--downloads,
  &--description,
  &--text {
    margin-top: $spacing-xl;
  }

  &--bullets {
    margin-top: $spacing-lg;
  }

  &--link {
    margin-top: $spacing-md;
    padding: 9px 30px !important;
  }
}
