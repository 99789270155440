.event-list-page {
  position: relative;

  &--title {
    @include list-page-title;
    @include screen-md {
      margin-bottom: $spacing7;
    }

    &--no-tabs {
      @include list-page-title;
      @include screen-lg {
        margin-bottom: $spacing4;
      }
    }
  }

  &--tab-list {
    margin: 0;
  }

  &--events {
    margin-top: $spacing-md;
  }
}
