.step-tag {
  padding: 8px;
  font-family: IBM;
  text-transform: uppercase;
  font-size: 12px;
  min-height: 24px;
  border-radius: 30px;
  max-width: fit-content;
  background-color: #d6e82b;
}
