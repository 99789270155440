.page-navigation {
  width: 100%;
  margin-top: $spacing-xl;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  //all links
  .link {
    align-items: center;
    display: flex;
    text-decoration: none;
  }

  //np-style parent element
  &.-large-article-theme {
    margin-bottom: $spacing-lg;

    //np-style links
    .link {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: none;
      border-radius: 10px;
      color: #1f1f1f;
      display: inline-flex;
      font-size: inherit;
      font-size: 16px;
      height: 40px;
      padding: 0 16px;
      text-align: center;
      text-decoration: none;
      transition: border-radius .2s;
    }
  }


  .-previous {

    .icon {

      fill: currentColor;
      stroke: transparent;
      transform: rotate(180deg);
      margin-right: 10px;
    }

    &.navigation-link {
      .link--text {
        @include underline-animation-reversed('span');
      }

      .action-link-icon {
        transform: rotate(180deg);
      }

    }

    &.navigation-link-np {

      box-shadow: inset 0 0 0 1px #1f1f1f;
      color: #1f1f1f;

      &:hover {
        background: $focus-color;
        box-shadow: none;
        text-decoration: none;

        .icon {
          color: white;
        }
      }
    }
  }

  .-next {

    flex-direction: row-reverse;
    margin-left: auto;

    &.navigation-link {
      .link--text {
        @include underline-animation('span');
      }

      .action-link-icon {
        margin-left: 10px;
      }
    }

    &.navigation-link-np {
      .icon {
        fill: white;
        margin-left: 10px;
      }

      box-shadow: inset 0 0 0 1px #1f1f1f;
      color: #1f1f1f;
      background-color: black;
      color: white;

      &:hover {
        background: $primary-blue;
        box-shadow: none;
        text-decoration: none;
        color: white;

        .icon {
          color: white;
        }
      }


      &.-is-first-page {
        text-transform: uppercase;
        background: $undrelab-purple;
        color: white;

        &:hover {
          background: $primary-blue;
        }

      }
    }

  }
}