$iconcolors: (
    'check-circle' : #058f05,
    'warning-triangle' : #ffd32c,
    'x-circle' : #b10909,    
    'wrench' : #000000

);

@each $name, $color in $iconcolors {
    .icon--#{$name} {
      color: $color;
    }
  
    @if $name == 'x-circle' {
      .icon--x-circle {
        color: $color;
      }
    }
    @if $name == 'x-circle' {
        .icon--wrench {
          color: $color;
        }
      }
      @if $name == 'warning-triangle' {
        .icon--wrench {
          color: $color;
        }
      }
  }

.status-page{
    
    &__mode{
        display: flex;
        gap: 2rem;
        justify-content: end;
        margin-top: 2rem;
    }

    &__status{
        display: flex;
        gap: 2rem;
        align-items: center;
     
    }

    &__message{
        margin-top: 2rem;
    }

    &__content{
        margin-top: 2rem;
    }

    &__collapse{
        display: block;
        border-bottom: 1px solid gray;

        h2{
            display: flex;
            align-items: center;
            gap: 1rem;
           
         }
    }

    &__text{
        display: flex;
        justify-content:space-between;
        align-items: center;
        
    }

    &__info{
        margin-left: 2rem;
    }
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
