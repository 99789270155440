.audio-player {
  background: $element-color--lightest;
  padding: 1rem 1.5rem;
  border-radius: 65px;

  @include screen-sm {
    border-radius: 50px;
  }

  &--row {
    display: flex;
    align-items: center;
  }

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  &--volume {
    position: relative;
    margin-left: auto;
  }

  &--button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem !important;
    width: 44px;
    height: 44px;
    color: black;
  }

  &--timeline-desktop {
    margin-left: 0.5rem;
    @include screen-sm-max {
      display: none;
    }
  }

  &--timeline-mobile {
    margin: 0 !important;
    padding: 1rem 0;
    @include screen-sm {
      display: none;
    }
  }
}
