.data-tag-list {
  @include remove-list-styles;
  display: flex;
  flex-wrap: wrap;

  &--item {

    &:not(:last-child)::after {
      content: '';
      display: inline-block;
      height: 12px;
      width: 1px;
      background: $primary-yellow-green;
      margin: 0 1rem;
    }
  }
}