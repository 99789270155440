.icon-warning {
  margin-right: $spacing-sm;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  stroke: none !important;

  & > [data-style='background'] {
    fill: $yellow;
  }

  &.-theme-red > [data-style='background'] {
    fill: $red;
  }

  &.-theme-blue > [data-style='background'] {
    fill: $blue-light;
  }

  &.-theme-gray > [data-style='background'] {
    fill: $element-color--lightest;
  }

  &.-size-wide {
    margin-right: $spacing-md;
    width: 36px;
    height: 36px;
  }
  &.-size-small {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    margin-top: unset;
  }
}
