.partner-logo-block{
  
   .image-with-link {
        background-color: $background-grey1;
    }
    .image-with-link--image {
        background-color: $background-grey1;
    }
    .image-with-link--description{
        padding: 10px;
        margin: auto;
    }
    .image-with-link--title {
        @include font-size-body;
        margin: auto;
    }

    .fluid-image--image{
        background-size: contain !important;
        background-repeat: no-repeat;
    }

    .content-area-item{
        margin-bottom: $spacing-lg;
    }

    .content-area.-flex-layout{
        display: flex;
        flex-wrap: wrap;
    }

    .content-area-item.-sizing-enabled{
        flex: 1 1 50%; 
        max-width: 50%; 

        @include screen-sm {
            flex: 1 1 33%; 
            max-width: 33%; 
          }

          @include screen-md {
            flex: 1 1 20%; 
            max-width: 20%; 
          }
    }
}