.office-block-info {
  h3 {
    @include font-body-headline;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .link[href^='tel:'] {
    color: $text-color;
    text-decoration: none;
  }
}
