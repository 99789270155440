.tag-link-list {
  margin: $spacing-md 0 0;
  text-align: center;

  &--left-align {
    text-align: left;
  }

  &--tag {
    margin-right: $spacing-xs;
    margin-top: $spacing-xs;

    &.-inactive {
      background-color: transparent;
    }
  }
}