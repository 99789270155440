.document-icon {
  position: relative;

  &--icon {
    width: 42px;
    height: 52px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.-size-xsmall {
      width: 32px;
      height: 32px;
    }

    &.-size-small {
      width: 32px;
      height: 42px;
    }

    &.-size-large {
      width: 56px;
      height: 66px;
    }

    &.-size-xlarge {
      width: 88px;
      height: 110px;
    }
  }

  &--asterix {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translate(40%, -30%);
  }
}
