.event-page {
  max-width: 1500px;
  margin: auto;

  &--heading {
    margin-top: 0;
    margin-bottom: $spacing-lg;
  }

  &--title {
    @include font-headline1;
    margin: 0;
  }

  &--header {
    @include font-size-sm;

    &-tag {
      @include font-data;
      margin-bottom: $spacing2;
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 90%;
      margin: 0 auto;

      @include screen-md-max {
        margin-top: $spacing-md;
      }
    }

    @include screen-md {
      display: flex;

      &-text {
        width: initial;
        margin: 0 0 0 $spacing8;
      }
    }

    &-left {
      width: 90%;
      margin: auto;

      @include screen-md {
        width: initial;
        margin: 0;
      }
    }

    &-media {
      margin-bottom: $spacing4;
    }

    &-registration {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: auto;

      p {
        margin-left: $spacing-lg;
      }
    }
  }

  &--image-container {
    position: relative;
    width: 100%;
    max-width: 660px;
    min-height: 400px;
    height: 100%;
    max-height: 700px;
    margin-bottom: 87px;
    margin: 0;

    @include screen-md {
      width: 45vw;
    }

    .fluid-image {
      border-radius: 0 !important;
    }
  }

  &--date-container {
    position: relative;
    display: flex;
    align-items: center;
    margin: $spacing4 0;

    &-date {
      @include font-body-headline;
      margin-right: $spacing4;
    }

    &-dates-more-container {
      position: relative;
      height: 32px;
    }

    &-dates-more {
      position: absolute;
      top: 0;
      left: 0;
      width: max-content;
      @include fluid-type(14px, 14px);

      & > button {
        height: 32px !important;

        &.-is-open {
          padding-left: 16px;
          padding-right: 150px;
        }
      }
    }
  }

  &--content {
    max-width: $content-width-md;
    margin-right: auto;
    width: 90%;

    @include screen-lg {
      padding-left: 112px;
    }
  }

  &--links {
    display: block;
    align-items: center;

    @include screen-lg {
      display: flex;
      flex-wrap: wrap;
    }

    & > * {
      @include screen-lg-max {
        margin-bottom: $spacing-sm;
      }
    }
  }

  &--text {
    margin-top: $spacing-xl;
  }

  &--speakers,
  &--schedule,
  &--contact {
    margin-top: $spacing-lg;
    border-top: 1px solid $element-color--lighter;
  }

  &--content-container {
    margin-top: $spacing7 !important;

    @include screen-lg {
      margin-top: $spacing9 !important;
    }
  }

  .content-with-sidebar.event-page--content-with-sidebar {
    // grid-template-columns: 200px 1fr;
    // grid-gap: 32px;
  }

  &--sidebar {
  }
}
