.table {
  overflow-x: auto;

  caption {
    margin-bottom: 24px;

    @include screen-md {
      margin-bottom: 32px;
    }
  }

  &--hover-trigger:hover {
    color: $primary-blue;

    .table--header-item {
      border-bottom: 1px solid $primary-blue;
      padding-bottom: 0;
    }
  }

  th.-is-active {
    color: $primary-blue;
  }

  &--header-item {
    display: flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
    padding-bottom: 1px;
  }

  &--sort-icon {
    margin-left: $spacing1;
    transition: transform $transition;
    color: inherit;

    &.-is-active {
      transform: rotate(180deg) translateY(2px);
    }
  }

  &--print {
    @media print {
      th,
      thead td {
        background: white;
        color: $text-color;
      }

      .table--header-item {
        span {
          display: none;
        }
      }
    }
  }
}

// Defined globally in order to also affect tables in XHtml strings
table {
  @include font-body;
  min-width: 100%;
  border-collapse: collapse;

  caption {
    font-weight: 400;
    font-size: convert-to-rem(24px) !important;
    line-height: 1.1;
    text-align: left;
    margin-bottom: $spacing-sm;
  }

  tr {
    vertical-align: top;
  }

  tbody tr:nth-child(2n + 1) {
    background: $background-grey2;
  }

  td,
  th {
    padding: $spacing-sm $spacing-md;
  }

  td {
    word-break: break-word;
    hyphens: auto;

    &.-is-number {
      white-space: nowrap;
    }

    &.-is-right-aligned {
      text-align: right;
    }
  }

  th,
  thead td {
    text-align: left;
    vertical-align: bottom;
  }
}
