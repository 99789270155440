.grouped-search-page-section-filter-list {
  @include remove-list-styles;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing8;

  &__item {
    position: relative;
  }

  &__button {
    @include remove-button-styles;
    @include font-data-large;
    padding: $spacing1 $spacing3;
    color: $text-color !important;
    margin-right: -1px;
    text-transform: uppercase;
    cursor: pointer;

    @include screen-sm-max {
      padding: $spacing2 $spacing3;
      font-size: 16px;
    }

    &:not(:last-child) {
      @include line-separator($height: 14px, $color: $background4);
    }

    &:before {
      left: 0;
      right: auto;
    }

    &:last-child {
      &:after {
        width: 1px;
      }
    }

    &.-lower-case {
      text-transform: none;
    }

    &.-is-current>span {
      border-bottom: 2px solid $primary-blue;
    }

    &:hover:not(:disabled)>span {
      border-bottom: 2px solid $primary-grey2;
    }

    &:not(.-is-current):disabled {
      color: $disabled !important;
      cursor: default;
    }
  }
}