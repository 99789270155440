.tibi-proposal-data {
    @include font-body;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    margin-bottom: $spacing-xl;

    @include screen-md-max {
        display: flex;
        flex-direction: column;
    }

    &--left,
    &--right {
        display: grid;
        grid-template-columns: 1fr;

        @include screen-md {
            grid-template-columns: 1fr 1fr;
        }
       
    }

    &--left {
        grid-column-start: 1;
    }

    &--right {
        grid-column-start: 2;
    }

    &--label {
        font-weight: bold;
        margin-right: 1.5rem;

        @media print {
            font-weight: 500;
        }

        &:after {
            content: ':';
        }
    }

    &--value {

        a {
            @include underline-animation-fill('span');
            text-decoration: none;
            color: $primary-grey3;

            &:not(:last-child):after {
                content: ', ';
            }
        }
    }

    &--value-multiple-contacts {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }


}