.stats-link-list {
  &--links {
    @include screen-md {
      display: flex;
      text-align: center;
    }
  }

  &--image {
    position: relative;
    width: 100%;
    margin: 60px 0 $spacing-lg;

    @include screen-md {
      margin: 80px 0 $spacing-xl;
    }
  }

  &--see-more-link {
    font-size: convert-to-rem(16px) !important;

    @include screen-md {
      float: right;
    }
  }
}
