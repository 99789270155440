.fact-box {
  border-bottom: 1px solid $divider-grey2;
  padding: $spacing4 $spacing4 !important;
  background-color: $background-grey2;

  &--with-top-border {
    border-top: 1px solid $divider-grey2;
  }

  &--bg-transparent {
    background-color: unset;
    padding: $spacing4 0px !important;

    .fact-box--icon {
      margin-right: 11.05px;
    }
  }

  @media print {

    // NOTE: Force visibility of collapsed content when printing
    >div {
      height: auto !important;
      overflow: visible !important;
      visibility: visible !important;
    }
  }

  &--content {
    @include font-size-sm;
    overflow: hidden;
    padding: $spacing4 0;
    @include fluid-type(16px, 18px);

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--header {
    width: 100%;
    padding: 0 !important;
    height: auto !important;
    cursor: pointer;

    &-title {
      @include fluid-type(20px, 24px);
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      text-align: left;

      span {
        flex: 1;
        margin-right: 1rem;
      }
    }
  }

  &--icon {
    @include accordion-icon;
  }
}