html {
  font-size: $font-size-base;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;

  @media print {
    font-size: 24px;
  }
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $text-color;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
}

body,
button,
input,
textarea {
  font-family: $font-stack;
  color: $text-color;
}

html:not(.mouse-user) :focus {
  @supports (outline: 5px solid $primary-blue) {
    outline: 5px solid $primary-blue;
  }
}

.mouse-user * {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  @include font-headline1;
  font-weight: normal;
  margin-top: $spacing-xxl;
  margin-bottom: $spacing-lg;
}

h2 {
  @include font-headline2;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  &--focusable {
      &:active,
      &:focus {
          position: static;
          width: auto;
          height: auto;
          margin: 0;
          overflow: visible;
          clip: auto;
          display: block;
      }
  }
}
