.content-area-item {
  margin-bottom: $spacing8;
  width: 100%;

  >* {
    // Ensures that floating styles in blocks don't cause problems with other blocks. Doing this on the direct children of .content-area-item allows individual blocks to override this behaviour.
    clear: both;
  }

  &:not(.-sizing-enabled):not(.-static-layout):last-child,
  &.-theme-no-margin {
    margin-bottom: 0;
  }

  &.-theme-large-margin {
    margin-bottom: $spacing-xxl;

    &:only-child {
      margin-bottom: 0;
    }
  }

  &.-sizing-enabled {
    padding: 0 ($box-padding / 2);
  }

  &--fallback {
    border: 1px solid $focus-color;
    padding: $spacing-lg;
  }

  &.-size-half,
  &.-size-third,
  &.-size-quarter,
  &.-size-two-thirds {
    @include screen-md {
      width: 50%;
    }
  }

  &.-size-third {
    @include screen-lg {
      width: 33.3%;
    }
  }

  &.-size-quarter {
    @include screen-lg {
      width: 25%;
    }
  }

  &.-size-full-screen {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding: 0;

    .rich-text-block {
      padding: unset;
    }
  }

  &.-size-two-thirds {
    @include screen-lg {
      width: 66.6%;
    }
  }
}