/*
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/source-sans-pro-regular.eot');
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('../assets/fonts/source-sans-pro-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/source-sans-pro-regular.woff2') format('woff2'),
    url('../assets/fonts/source-sans-pro-regular.woff') format('woff'),
    url('../assets/fonts/source-sans-pro-regular.ttf') format('truetype'),
    url('../assets/fonts/source-sans-pro-regular.svg#SourceSansPro')
      format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/source-sans-pro-600.eot');
  src: local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'),
    url('../assets/fonts/source-sans-pro-600.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/source-sans-pro-600.woff2') format('woff2'),
    url('../assets/fonts/source-sans-pro-600.woff') format('woff'),
    url('../assets/fonts/source-sans-pro-600.ttf') format('truetype'),
    url('../assets/fonts/source-sans-pro-600.svg#SourceSansPro') format('svg');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/source-serif-pro-regular.eot');
  src: local('Source Serif Pro Regular'), local('SourceSerifPro-Regular'),
    url('../assets/fonts/source-serif-pro-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/source-serif-pro-regular.woff2') format('woff2'),
    url('../assets/fonts/source-serif-pro-regular.woff') format('woff'),
    url('../assets/fonts/source-serif-pro-regular.ttf') format('truetype'),
    url('../assets/fonts/source-serif-pro-regular.svg#SourceSerifPro')
      format('svg');
}
*/

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/nunito-600.ttf');
}

@font-face {
  font-family: 'IBM';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/ibm-plex-mono-400.ttf');
}

@font-face {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/pp-neue-montreal-400.woff');
}
@font-face {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/pp-neue-montreal-500.woff');
}
@font-face {
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/pp-neue-montreal-600.woff');
}
