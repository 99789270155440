.tab-menu {
  position: absolute;
  top: $spacing-md;
  left: $spacing-md;
  z-index: 100;
  background-color: $text-color;
  color: white;
  padding: $spacing-md;
  transform: translateY(-200%);

  &:focus-within {
    transform: none;
  }

  ul {
    @include remove-list-styles;
    margin: 0;
    padding: 0;
  }

  li {
    padding: $spacing-xs;

    &:not(:last-child) {
      border-bottom: 1px solid $element-color--darker;
    }
  }

  button {
    color: inherit;
  }
}
