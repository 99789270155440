.chapter-link {
  display: flex;
  text-decoration: none !important;
  color: $primary-grey3 !important;
  font-size: convert-to-rem(18px);

  @media print {
    font-size: convert-to-rem(16px);
  }

  &:not(.-theme-underline) {
    .link--text {
      @include underline-animation('span');

      @media print {
        font-weight: 400;
      }

      span {
        padding-bottom: 5px;

        @media print {
          font-size: convert-to-rem(16px);
        }
      }
    }
  }

  &--number {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 0.4rem;
  }

  &--dash {
    margin-right: 0.3rem;
    margin-left: -0.1rem;

    &:before {
      content: '-';
    }
  }

  &.-theme-underline {
    border-bottom: 1px solid $primary-blue;
    line-height: 164%;
    width: fit-content;

    @media print {
      border-bottom: none;
    }
  }

  &.-theme-extra-indentation {
    margin-left: 40px;
  }
}
