.status {
  @include font-data;
  display: flex;
  align-items: center;

  &.-align-center {
    justify-content: center;
  }

  &.-result-is-published {
    &:before {
      background: #c0c1ff;
    }
  }

  &.-is-completed {
    &:before {
      background: $background4;
    }
  }

  &.-is-active {
    &:before {
      background-color: #00cb6d;
    }
  }

  &.-is-planned {
    &:before {
      background-color: $text-color;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: sub;
    height: 8px;
    width: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: $info-red;
    margin-right: $spacing-sm;
  }
}
