.event-card-list {
  &--item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--dates {
    position: relative;
    margin-top: 44px;
  }

  &--date {
    @include fluid-type(16px, 20px);
    font-weight: 600;
  }

  &--dates-more {
    top: -10px;
    right: 0px;
  }
}
