@mixin accordion-icon {
  color: $primary-blue;
  transition: transform $transition;

  &.-is-open {
    transform: rotate(180deg);
  }
}

@mixin aspect-ratio($ratio) {
  aspect-ratio: $ratio;

  @supports not (aspect-ratio: #{$ratio}) {
    &::before {
      content: '';
      float: left;
      padding-top: calc((1 / #{$ratio}) * 100%);
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

@mixin vertical-bar {
  content: '';
  position: absolute;
  right: 0;
  background: $primary-blue;
  height: 100%;
  width: $spacing4;
  z-index: 1;

  @include screen-md {
    width: $spacing7;
  }

  @content;
}

@mixin timeline-marker {
  content: '';
  position: absolute;
  z-index: 2;
  height: $spacing-md;
  width: $spacing-md;
  border-radius: 100%;
  @content;
}

@mixin external-link-style {
  margin-right: 12px;

  &>span {
    display: inline-flex;
    position: relative;

    .icon {
      // Displays all link icons in a row
      display: inline;
    }

    .external-link-icon {
      width: 0.5em;
      height: 0.5em;
      margin-left: 0.3em;
      color: inherit;
    }
  }
}

@mixin has-section-title() {
  display: flex;
  padding: 0.83em 0em;

  h2.heading.portfolio-content--title {
    margin: unset;
  }

  @include screen-md {
    justify-content: space-between;
    align-items: flex-end;

    h2.heading.portfolio-content--title {
      padding: unset;
    }

    @media print {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75em;
    }

  }

  @include screen-md-max {
    flex-direction: column;

    h2.heading.portfolio-content--title {
      padding-bottom: 16px;
    }
  }
}

@mixin cf() {
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin underline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: $element-color--light;
  }
}

@mixin grid($columns, $margin) {
  width: calc((100% - (#{$margin} * #{$columns - 1})) / #{$columns});

  //Set margins on each to override previously set margins from MQs
  &:nth-child(n) {
    margin-right: $margin;
    margin-bottom: $margin;
  }

  &:nth-child(#{$columns}n) {
    margin-right: 0;
  }
}

@mixin visually-hidden {
  position: absolute;
  left: -999em;
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  overflow: hidden;
}

@mixin visually-hidden-button {
  &:not(:focus) {
    @include visually-hidden();
  }
}

@mixin focus-outline {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

@mixin outline {
  box-shadow: inset 0 0 0 1px $element-color--light;
  border-radius: $border-radius-lg;
}

@mixin remove-list-styles {
  list-style: none;
  padding: 0;
}

@mixin remove-button-styles {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: inherit;
  padding: 0;
}

@mixin quantity-selector($selector-base, $quantity) {

  #{$selector-base}:nth-last-child(#{$quantity}):first-child,
  #{$selector-base}:nth-last-child(#{$quantity}):first-child~#{$selector-base} {
    @content;
  }
}

@mixin orange-bar($top: auto, $bottom: 0, $width: 32px, $height: 4px) {
  content: '';
  position: absolute;
  height: $height;
  width: $width;
  bottom: $bottom;
  top: $top;
  background-color: $focus-color;
}

@mixin list-page-title {
  @include font-display2;
  margin: $spacing5 0 $spacing-xl;

  @include screen-md {
    margin: $spacing-xxl 0;
  }
}

@mixin line-separator($pseudo-element: 'after',
  $side: 'right',
  $color: $element-color--light,
  $height: 16px) {
  position: relative;

  &:#{$pseudo-element} {
    content: '';
    position: absolute;
    #{$side}: 0;
    top: 50%;
    width: 1px;
    height: $height;
    background: $color;
    transform: translateY(-50%);
  }

  &:last-of-type {
    &:after {
      width: 0px;
    }
  }
}

@mixin underline-animation($target) {

  //Target needs to be a span element for the animation to work correctly
  #{$target} {
    padding-bottom: 2px;
    background-image: linear-gradient($primary-blue, $primary-blue);
    background-repeat: no-repeat;
    transition: background-size $transition;
  }

  #{$target} {
    background-size: 0% 1px;
    background-position: 0% 100%;
  }

  &:hover #{$target},
  &:focus #{$target} {
    background-size: 100% 1px;
  }

  &:focus #{$target} {
    .mouse-user & {
      background-size: 0% 1px;
    }
  }
}

@mixin underline-animation-reversed($target) {

  // Target needs to be a span element for the animation to work correctly
  #{$target} {
    padding-bottom: 2px;
    background-image: linear-gradient($primary-blue, $primary-blue);
    background-repeat: no-repeat;
    transition: background-size $transition;
  }

  #{$target} {
    background-size: 0% 1px;
    background-position: 100% 100%;
  }

  &:hover #{$target},
  &:focus #{$target} {
    background-size: 100% 1px;
  }

  &:focus #{$target} {
    .mouse-user & {
      background-size: 0% 1px;
    }
  }
}

@mixin underline-animation-fill($target) {

  //Target needs to be a span element for the animation to work correctly
  #{$target} {
    padding-bottom: 2px;
    background: linear-gradient(to right,
        (rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24))),
      linear-gradient(to right, $primary-blue, $primary-blue);
    background-repeat: no-repeat;
    transition: background-size $transition;
    background-size: 100% 1px, 0 1px;
    background-position: 100% 100%, 0 100%;
  }

  &:hover #{$target},
  &:focus #{$target} {
    background-size: 0 1px, 100% 1px;
  }

  &:focus #{$target} {
    .mouse-user & {
      background-size: 0 1px, 100% 1px;
    }
  }
}

// Useful for delaying the visibility of things that should only be visible if javascript is disabled
@mixin non-js-reveal {
  animation: non-js-reveal 0s 2s backwards;
}

@keyframes non-js-reveal {
  from {
    visibility: hidden;
    position: absolute;
  }

  to {
    visibility: visible;
    position: static;
  }
}

@mixin delay($from, $to, $duration, $beginTransition, $properties...) {
  animation-name: delay;
  animation-duration: $duration;
  animation-timing-function: ease-out;

  @keyframes delay {
    0% {
      @each $property in $properties {
        #{$property}: $from;
      }
    }

    #{$beginTransition} {
      @each $property in $properties {
        #{$property}: $from;
      }
    }

    100% {
      @each $property in $properties {
        #{$property}: $to;
      }
    }
  }
}

@mixin notIE {
  @supports not ((-ms-high-contrast: none) or (-ms-high-contrast: active)) {
    @content;
  }
}

@mixin IE {

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    @content;
  }
}

@mixin cursor-screen-only {
  @media not all and (pointer: coarse) {
    @content;
  }
}

@mixin input-placeholder() {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @content;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }
}

// Breakpoints
@mixin screen-xs {
  @media (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin screen-md-with-height {
  @media (min-height: $breakpoint-lg) and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin screen-md-with-height-max {

  @media (max-height: $breakpoint-lg),
  (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin screen-xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin screen-xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin screen-xs-max {
  @media (max-width: $breakpoint-xs-max) {
    @content;
  }
}

@mixin screen-sm-max {
  @media (max-width: $breakpoint-sm-max) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: $breakpoint-md-max) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: $breakpoint-lg-max) {
    @content;
  }
}

@mixin screen-xl-max {
  @media (max-width: $breakpoint-xl-max) {
    @content;
  }
}

@mixin screen-xxl-max {
  @media (max-width: $breakpoint-xxl-max) {
    @content;
  }
}