.table-mobile-sort {
  position: relative;

  &--label {
    @include font-data;
    display: block;
    margin-bottom: $spacing1;
  }

  &--dropdown-button {
    @include font-tiny;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    margin-bottom: $spacing-lg;
    padding: 6px 20px 6px 20px;
    border-radius: $radius1;
    border: 1px solid $primary-grey2;
    background: none;
  }

  &--sort-button {
    @include font-body-subtitle;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    padding: $spacing2 $spacing3;
    letter-spacing: 0.3px;

    &.-activated {
      color: $primary-blue;
    }
  }

  &--collapse {
    position: absolute;
    z-index: 60;
    width: 100%;
    top: 0;
  }

  &--dropdown {
    padding: $spacing4 $spacing4 $spacing6;
    border-radius: 8px;
    border: 1px solid $primary-grey2;
    background: white;

    &-title {
      @include font-data;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $divider-grey1;
      padding: 0 0 20px $spacing3;
      margin: 0;
    }

    &-list {
      padding: 0;
      list-style: none;
      margin: 0;
    }

    &-item {
      text-align: center;
      border-bottom: 1px solid $divider-grey1;
    }
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: 50;
  }
}
