.process-item {
  $self: &;
  position: relative;
  background-color: white;
  transition: height 0.1s ease-in;

  &--link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 48px;

    &--has-link {
      @include underline-animation(#{$self}--title span);
      @include underline-animation(#{$self}--text-hover span);


     & .process-item--title {
      > span::before{
        display: block;
        content: "\2192";
        display: revert;
        margin-right: 10px;
      }
    }
    }
  }

  &--wrapper {
    height: 100%;
    width: 100%;
    padding: $spacing1;
  }

  &--button {
    @include remove-button-styles;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 100;
    padding: 0 10px 0 10px;
    bottom: 25px;
    height: auto;
    transition: 0.2s;
    color: $primary-blue;

    &:hover {
      & > *::after {
        transform: scaleX(1);
      }
    }
  }

  &--header {
    color: $focus-color;
    display: flex;
    flex-direction: column;
  }

  & &--number {
    color: $primary-blue;
    font-size: convert-to-rem(76px);
    height: 3rem;
    line-height: 2.7rem;
    font-weight: 500;
    margin: 2rem 0 $spacing4 0;
  }

  &--icon {
    margin: 2.5rem 0 $spacing4 0;
    width: 40px;

    &-container {
      width: 100%;
      height: 104px;
    }
  }

  &--arrow {
    margin: 0 auto;
  }

  &--title {
    @include font-headline3;
    margin-bottom: $spacing3;
    margin-top: 0;
  }

  &--text {
    align-self: stretch;
    margin-top: $spacing-xs;
    margin-bottom: 0;
  }
}
