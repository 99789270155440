.search-input {
  position: relative;

  &--input {
    width: 100%;

    input {
      padding-left: $spacing6 !important;
    }
  }

  &--input:focus + &--icon {
    display: none;
  }

  &--icon {
    position: absolute;
    top: 50%;
    left: $spacing-md;
    transform: translateY(-50%);
    color: $primary-grey3;
  }
}
