.proposal-list-page {
  &--top {
    margin-bottom: $spacing6 !important;

    @include screen-sm {
      margin-bottom: $spacing8 !important;
    }
  }

  &--title {
    @include list-page-title;
  }

  &--hide-underline-overflow {
    overflow-x: hidden;
  }

  &--tab-list {
    display: block;
    margin: 0;
  }
}
