.search-result-group {
  margin-bottom: $spacing8;

  &:not(:first-child) {
    .search-result-group--title {
      margin-top: $spacing4;
    }
  }

  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background-grey2;
    width: 100%;
    padding: 14px $spacing4 14px $spacing2;

    h2 {
      @include font-large-subtitle;
      margin: 0;
      text-transform: uppercase;
    }
  }
}
