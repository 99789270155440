.player-timeline {
  flex: 1;
  padding: 1rem 0;

  &--track {
    position: relative;
    height: 2px;
    background: $text-color;
  }

  &--marker {
    position: absolute;
    border-radius: 100%;
    top: -3px;
    left: 0;
    width: 8px;
    height: 8px;
    background: $text-color;
  }
}
