.contact-block {
  padding: $box-padding;
  background-color: $background-grey2;
  height: 100%;
  color: $primary-grey3;

  h2 {
    @include font-headline3;
    padding-bottom: $spacing-md;
    margin-top: 0;
    border-bottom: 1px solid $background-grey3;
    display: flex;
    justify-content: space-between;

    svg.icon {
      width: 30px;

      > path {
        stroke-width: 1.9;
      }
    }
  }

  .content-area-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  > ul {
    @include remove-list-styles;
  }
}
