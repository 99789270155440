.newsletter-administration-layout {
  background-color: $element-color--lightest;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  h1 {
    @include font-size-xl;
    font-weight: bold;
  }

  h2 {
    @include font-size-lg;
    font-weight: bold;
    margin-bottom: $spacing-lg;
  }
}
