.message {
  background-color: $info-yellow;
  padding: 13px;

  &.is-translation-label {
    padding: 1px 10px;
    border-radius: 50px;
    width: min-content;
    white-space: nowrap;
  }

  &.-theme-red {
    background-color: $info-red;
  }

  &.-theme-blue {
    background-color: $info-blue;
    color: black;
  }

  &.-theme-gray {
    background-color: $element-color--lightest;
    color: black;
  }

  &--icon--hide {
    opacity: 0;
  }

  &--content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    @include screen-md {
      max-width: 800px;
      margin: auto;
    }
  }

  &--text {
    @include font-size-body;
  }

  &--small-text {
    @include font-size-xs;
  }

  a {
    color: inherit;
  }
}
