.campaign-block {
  $self: &;

  @mixin column {
    flex-direction: column;

    .campaign-block--image {
      width: 100%;

      &::before {
        left: unset;
        right: 0;
        width: 24px !important;
      }
    }

    .campaign-block--text {
      width: 100%;
    }

    .campaign-block--title {
      margin-bottom: $spacing3;
    }

    .campaign-block--description {
      margin-bottom: $spacing7;
    }
  }

  display: flex;
  position: relative;
  background-color: $background-grey2;
  flex: 1;
  min-height: 360px;

  @include screen-md-max {
    @include column;
  }

  @include screen-md {
    flex-direction: row;
  }

  &.-theme-dark {
    background-color: $primary-grey3;
    color: white;

    .campaign-block {
      &--image {
        &::before {
          background: $primary-yellow;
        }
      }
    }

    .campaign-block--link {
      color: white !important;
    }
  }

  &.-theme-prominent {
    position: absolute;
    left: 0;
    width: 100%;

    .campaign-block {
      &--title {
        @include font-headline1;
      }

      &--description {
        @include fluid-type(20px, 24px);
      }

      &--image {
        @include screen-md {
          width: 50%;
        }

        &:before {
          width: 24px !important;

          @include screen-sm {
            width: 32px !important;
          }

          @include screen-lg {
            width: 48px !important;
          }
        }
      }
    }
  }

  &.-theme-np {

    .campaign-block {
      &--image {
        &::before {
          background: $curie;
        }
      }
    }
  }

  &.-small {
    @include column;

    .campaign-block--image {
      height: 439px;
    }
  }

  &.-extra-small {
    @include column;

    .campaign-block--image {
      height: 320px !important;
    }
  }

  /*
  &.-is-reversed {
    display: flex;

    @include screen-md {
      flex-direction: row-reverse;

      .campaign-block--image::before {
        left: unset;
        right: 0;
      }
    }
  }
  */
  &--title {
    @include fluid-type(28px, 30px);
    font-weight: normal;
    margin-top: 0;
    line-height: 1.3;
  }

  &--description {
    @include font-large;
    margin-top: 0;
    white-space: pre-wrap;
  }

  &--link {
    width: max-content;
    margin-top: auto;
  }

  &--image {
    position: relative;

    @include screen-md-max {
      height: 320px !important;
    }

    @include screen-md {
      width: calc(2 / 3 * 100%);
      height: auto;
    }

    &::before {
      @include vertical-bar {
        left: 0;
        width: 32px !important;
      }
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    padding: $spacing6;
    flex: 1;

    .button {
      font-size: convert-to-rem(16px);
    }
  }
}

.campaign-block-container {

  @media print {
    min-height: 360px !important;

    .-theme-prominent {
      position: relative;
      width: auto;
      height: 100%;
    }
  }
}