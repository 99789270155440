.article-block {
  display: block;

  &.-theme-with-border {
    border-bottom: 1px solid $element-color--light;
    margin-top: $spacing-lg;
  }

  &.-theme-vertical {
    .article-block__content {
      display: block;
    }

    .list-item-image {
      width: 100%;
    }

    .article-block--title {
      color: black;
      line-height: 1.2;
    }
  }

  &.-theme-publication {
    height: 100%;

    .article-block__text-container {
      padding: $spacing-md 0;
    }
  }

  &__content {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-xl;

    @include screen-md {
      display: flex;
    }

    &.-theme-no-margin {
      margin: 0;
    }

    &.-theme-with-border {
      margin-bottom: $spacing-lg;
      margin-top: 0;
    }

    &.-theme-sidebar {
      display: block;
    }
  }

  &--title {
    @include font-size-xl;
    margin-top: 0;
    margin-bottom: $spacing-md;
    display: block;
    font-weight: normal;

    &.-theme-sidebar {
      @include font-size-lg;
      margin-bottom: $spacing-sm;
    }
  }

  &--event-image-container {
    position: relative;
    min-height: 200px;
  }

  &--document-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  &--document-image {
    height: 193px;
    width: 136px;
  }

  &__text-container {
    flex: 1;
  }

  &--text {
    margin-top: $spacing-md;
    margin-bottom: 0;
  }
}
