.checkbox-group {
  margin: $spacing-xl 0;

  &--title {
    @include font-size-body;
    margin: 0 0 $spacing-md;
  }

  &--checkbox {
    @include font-size-sm;
    margin: $spacing-sm 0;
  }

  &--validation-error {
    color: red;
  }
}
