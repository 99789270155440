.image-with-caption {
  &:not(.-theme-no-margin) {
    margin: $spacing-lg 0;
  }

  &.-theme-half-width {
    @include screen-sm {
      float: left;
      width: 50%;
      margin: 0;
      margin-right: $spacing-lg;
    }
  }

  &--caption {
    @include font-size-xs;
    color: $text-color;

    margin: $spacing-md 0;
  }

  &--is-external {
    img {
      width: unset !important;
    }
  }

  img {
    width: 100%;
  }
}
