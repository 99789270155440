.article-card-list {
  display: flex;

  .card {
    &--title {
      @media print {
        margin: 12px 24px 8px 0;
      }
    }

    &--text {
      margin-right: 24px;
    }
  }
}