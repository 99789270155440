.step {
  display: flex;
  position: absolute;
  transform: translate(calc(-110px + 60px), -50%);
  z-index: 1;
  transition: 0.3s;
  border: none;
  background: none;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);

  &.-big {
    transform: translate(-66px, -50px);
    width: auto;

    @include screen-lg {
      height: 160px;

      .step-circle {
        width: 100px;
        height: 100px;
      }
      .step-text {
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        transition: unset;
      }

      .step-number {
        height: unset;
      }

      &.-hover-on {
        transform: translate(-100px, calc(-100px + 25px));
        height: 250px;

        .step-circle {
          width: 200px;
          height: 200px;
          border-radius: 100%;
        }

        .step-text {
          width: 110px;
          font-size: 22px;
          animation: text-in 0.3s;
        }
      }

      &.-hover-off {
        .step-text {
          animation: text-out 0.3s;
        }
      }
    }
  }

  &.-hover-on,
  &.-is-active {
    .step-circle {
      border-radius: 35px;
    }

    .step-number {
      display: none;
    }

    .step-text {
      top: calc(50% - 2px);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.-light-text {
      .step-text {
        color: white;
      }
    }
  }

  &.-hover-on {
    .step-text {
      text-decoration: underline;
    }
  }

  &-circle {
    position: relative;
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    transition-duration: 0.3s;
    transition-property: border-radius, height, width;
    transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
  }

  &-text {
    position: absolute;
    top: calc(50% - 2px);
    left: 120%;
    transform: translateY(-50%);
    width: max-content;
    font-size: 20px;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-property: top, left, transform;
    text-align: center;
    transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
    color: $text-color;
  }

  &-number {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 24px;
  }
}

@keyframes text-in {
  0% {
    font-size: 20px;
    top: 110%;
    transform: translateX(-50%);
    width: 100px;
    opacity: 1;
  }
  40% {
    top: 110%;
    transform: translateX(-50%);
    font-size: 20px;
    width: 100px;
    opacity: 0;
  }
  41% {
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 110px;
    font-size: 22px;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-out {
  0% {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 110px;
    font-size: 22px;
    opacity: 1;
  }
  40% {
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 110px;
    font-size: 22px;
  }
  41% {
    top: 110%;
    transform: translateX(-50%);
    font-size: 20px;
    width: 100px;
    opacity: 0;
  }
  100% {
    font-size: 20px;
    top: 110%;
    transform: translateX(-50%);
    width: 100px;
    opacity: 1;
  }
}
