.related-content {
  margin: 0 auto $spacing-lg;

  &.-is-grid {
    max-width: 100%;

    .related-content--link {
      text-align: center;

      &-inner {
        padding: 10px $spacing-lg;
        font-size: convert-to-rem(16px);
      }

      @include screen-md {
        text-align: right;

        &-inner {
          font-size: convert-to-rem(20px);
          margin-top: $spacing-lg;
          padding: $spacing-md $spacing-xl;
        }
      }
    }
  }

  &--title {
    background-color: $background-color;
    color: white;
    padding: $spacing-sm $spacing-md;
    margin-bottom: $spacing-lg;
  }

  >ul {
    @include remove-list-styles;
    margin: 0;

    >li:first-child {
      padding-top: 0;
    }
  }

  &--item {
    padding: $spacing-lg 0;

    &.-border-top {
      border-top: 1px solid $element-color;
      padding-top: $spacing-md  !important;
    }

    &.-border-bottom {
      border-bottom: 1px solid $element-color;
    }
  }

  &--link {
    margin-top: $spacing-lg;
  }

  &__title {
    @include font-size-body;
    font-weight: 600;
  }
}