.quotes-block {
  @include screen-md-max {
    padding: $np-block-padding;
  }

  &--title {
    @include fluid-type(32px, 52px);
    font-weight: 600;
    font-family: $font-family-np;
    line-height: 1.3;
    width: 14rem;

    @include screen-md {
      width: 25rem;
    }
  }

  &--quote {
    &:nth-child(odd) {
      //Right side
      .quotes-block--content {
        .quotes-block--image {
          transform: translateX(10rem);
        }
        .quotes-block--texts {
          transform: translateX(-10rem);
        }
      }

      .quotes-block--image {
        margin: 0 0 $spacing-md auto;
      }

      .quotes-block--texts {
        margin-left: $spacing-lg;
      }
    }

    @include screen-md {
      //Right side
      &:nth-child(odd) {
        .quotes-block--content {
          margin: auto 0 0 auto;
          flex-direction: row-reverse;
        }
        .quotes-block--texts {
          justify-content: flex-start;
        }
        .quotes-block--image {
          margin: 0 0 auto $spacing-xl;
        }
      }
    }
  }

  &--content {
    margin: 0 auto 0 0;

    @include screen-md {
      display: flex;
      width: 90%;
      padding: 0 $spacing-xl;
    }

    @include screen-xl {
      width: 80%;
    }

    .quotes-block--image {
      transform: translateX(-5rem);
      transition: transform 0.7s;
    }

    .quotes-block--texts {
      transform: translateX(5rem);
      transition: transform 1s cubic-bezier(0.2, 0.7, 0.45, 1);
    }

    &.-animate-in {
      .quotes-block--image,
      .quotes-block--texts {
        transform: translateX(0) !important;
      }
    }
  }

  &--texts {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include fluid-type(18px, 24px);

    @include screen-md-max {
      width: 90%;
    }
  }

  &--paragraph {
    margin-top: 0;
  }

  &--image {
    max-width: 60%;
    max-height: 300px;
    margin-bottom: $spacing-md;

    @include screen-md {
      max-width: 50%;
      margin: auto $spacing-xl 0 0;
    }
  }
}
