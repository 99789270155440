.media-block {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  &.-has-title {
    justify-content: space-between;
  }

  h2 {
    font-weight: 500;
    margin: 0 0 $spacing4 0;
  }

  &__link {
    margin-top: $spacing4;
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 56%;
    overflow: hidden;
  }

  &__youtube-icon {
    position: absolute;
    z-index: 2;
    margin: $spacing2 0 0 $spacing2;
  }

  &__embed {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}
