.text-with-sidebar {
  border-bottom: 1px solid $element-color--lighter;

  .accordion-with-content-area--collapse.-closed &--top-line {
    position: absolute;
    top: 0;
    left: $spacing5;
    width: calc(100% - #{$spacing8});
    border-top: 1px solid $element-color--lighter;
  }

  h4 {
    @include font-size-body;
    margin-bottom: 0.3em;
    color: $element-color--darker;
  }

  h2 {
    font-size: 1.5em;
  }

  &--title {
    margin-top: 0;
    margin-bottom: $spacing2;
  }

  &--links {
    margin-bottom: $spacing7;
  }

  &--content {
    margin-bottom: $spacing7;
  }

  &--sidebar {
    .content-area-item {
      margin-bottom: $spacing5;
    }
  }
}
