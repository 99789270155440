.button {
  @include remove-button-styles;
  text-decoration: none;
  text-align: center;
  font-size: $font-size-base;
  border-radius: $radius1;
  transition: border-radius 0.2s;
  padding-left: $spacing3;
  padding-right: $spacing3;
  height: 40px;
  display: inline-flex;
  align-items: center;
  color: $text-color;

  // This selector is too verbose to repeat
  @mixin hoverSelector {
    @at-root {

      button#{&}:not(:disabled):hover,
      &:link:hover,
      &:visited:hover {
        @content;
      }
    }
  }

  // This selector is also too verbose to repeat
  @mixin disabledSelector {

    // Selects any 'disabled' .button, or any non-clickable .button
    &:disabled,
    &:not(button):not(:link):not(:visited) {
      @content;
    }
  }

  // This is needed because in a lot of places the Link component uses these styles, and the Link component is often rendered even when both text and url are empty strings (because that's the way the backend provides the data). The Link needs to be rendered to the DOM in order for on page editing to work. The below style ensures that even though the element exists on the page, it is invisible to the user.
  // 'root' for extra specificity
  :root &:empty {
    display: none;
  }

  &.-theme-dropdown {
    .icon {
      width: 13px;
    }
  }

  &.-theme-link {
    color: $link-color;

    &:not(:disabled):hover {
      text-decoration: underline;
    }
  }

  &:link,
  &:visited {
    &.-is-external-link {
      @include external-link-style;
    }
  }

  &.-theme-link.-theme-primary {
    background: $link-color;
  }

  &.-theme-small {
    padding-right: $spacing2;
    padding-left: $spacing2;
    height: 32px;
    @include font-tiny;
  }

  &.-theme-large {
    height: 56px;
    @include font-body;
  }

  &.-theme-primary {
    background-color: $primary-grey3;
    color: white;

    &.-theme-light {
      background: white;
      color: $primary-grey3;
    }

    @include hoverSelector {
      background-color: $primary-blue;
      border-radius: 0;
    }

    @include disabledSelector {
      background-color: $background-grey3;
      color: #00000080;
    }
  }

  &.-theme-secondary {
    color: $primary-grey3;
    border: 1px solid $primary-grey3;

    &.-theme-light {
      color: white;
      border-color: white;
    }

    @include hoverSelector {
      color: $primary-blue;
      border-radius: 0;
      border-color: $primary-blue;
    }

    @include disabledSelector {
      color: #1f1f1f80;
      border-color: #1f1f1f80;
    }
  }

  &.-theme-signal {
    background: $primary-blue;
    color: white;

    @include hoverSelector {
      border-radius: 0;
    }

    @include disabledSelector {
      background-color: $background-grey3;
      color: #00000080;
    }
  }

  &.-theme-light {
    @include hoverSelector {
      color: white;
      border-radius: 0;
    }

    @include disabledSelector {
      background-color: $background-grey3;
      color: #00000080;
    }
  }

  /*
  &.-theme-orange-outline {
    color: $background-color;
    box-shadow: inset 0 0 0 1px $focus-color;

    @include hoverSelector {
      background-color: $focus-color;
      color: $text-color; // Need to repeat this here in order for ensure the correct color when used in combination with other themes
      text-decoration: underline;
    }
  }

  &.-theme-link-primary,
  &.-theme-link-secondary {
    border-radius: $border-radius-lg;
    padding: $spacing-xs 10px;
    @include fluid-type(14px, 18px);

    @include screen-md {
      padding: $spacing-sm 20px;
    }

    @include hoverSelector {
      text-decoration: underline;
    }
  }

  &.-theme-link-primary {
    color: $text-color;
    background-color: $focus-color;
  }

  &.-theme-link-secondary {
    color: white;
    box-shadow: inset 0 0 0 1px $focus-color;
  }

  &.-theme-outline {
    box-shadow: inset 0 0 0 1px $element-color--light;
    color: $link-color;

    @include hoverSelector {
      background-color: $element-color--light;
      color: $link-color; // Need to repeat this here in order for ensure the correct color when used in combination with other themes
    }
  }

  &.-theme-outline,
  &.-theme-orange-outline {
    @include disabledSelector {
      box-shadow: inset 0 0 0 1px rgba($element-color, 0.5);
      color: rgba($element-color, 0.5);
    }
  }

  &.-theme-uppercase {
    @include font-size-body;
    font-weight: normal;
    text-transform: uppercase;
  }
  */

  &.-has-icon-left,
  &.-has-icon-right {
    display: flex;
    align-items: center;
  }

  &.-has-children.-has-icon-right {
    svg {
      margin-left: 0.5rem;
    }
  }

  &.-has-children.-has-icon-left.-theme-small {
    svg {
      margin-right: $spacing-sm;
    }
  }

  &.-has-children.-has-icon-left {
    svg {
      margin-right: 0.5rem;
    }
  }

  &.-has-icon-right.-theme-small {
    svg {
      margin-left: $spacing-sm;
    }
  }

  &.-theme-big {
    @include font-body;
    height: 56px;
  }

  /*
  &.-theme-white {
    background-color: transparent;
    color: white;
  }

  &.-theme-medium {
    @include fluid-type(14px, 18px);

    @include screen-md-max {
      padding: 8px 12px;
    }
  }

  &.-theme-big {
    @include font-size-lg;
    padding: 10px $spacing-xl;
    border-radius: 45px;
  }
  */
}