.announcement {
  border: 1px solid $primary-yellow;
  background: rgba(214, 232, 43, 0.05);
  padding: 16px;
  font-size: 16px;
  --icon-width: 24px;
  --icon-gap: 16px;

  @media (min-width: 768px) {
    padding: 32px;
  }

  &__heading {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    gap: 8px var(--icon-gap);

    margin-bottom: 20px;
    grid-template-areas:
      'icon title'
      'sub-title sub-title';

    @media (min-width: 768px) {
      align-items: end;
    }
  }

  &__icon {
    grid-area: icon;
    height: 24px;
    width: var(--icon-width);
  }

  &__title {
    margin: 0;
    grid-area: title;
    font-family: Arial;
    font-weight: bold;
    font-size: inherit;
  }

  &__sub-title {
    margin: 0 0 0 calc(var(--icon-width) + var(--icon-gap));
    grid-area: sub-title;
    font-size: inherit;
    padding: 0;
    font-family: IBM;
  }

  &__link-wrapper {
    display: flex;
    margin-left: calc(var(--icon-width) + var(--icon-gap));
    gap: 16px 0;
    flex-direction: column;

    @media (min-width: 768px) {
      gap: 0;
    }
  }

  &__link-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    font-family: IBM;
    font-size: inherit;
  }

  &__link-label {
    margin-left: 1px;
  }

  &__link {
    text-decoration: underline;
    color: $primary-grey3;
  }
}
