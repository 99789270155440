.date-field {
  position: absolute;
  background: $link-color;
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 81px;
  line-height: 60px;

  &:before {
    @include orange-bar();
    width: 110px;
    height: 6px;
    bottom: 21px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.-theme-medium {
    font-size: convert-to-rem(30px);
    height: 70px;

    &:before {
      bottom: 10px !important;
    }
  }

  &.-transparent-background {
    background: rgba(0, 0, 0, 0.4);
  }

  &.-theme-small {
    font-size: 1rem;
    height: 37px;
    line-height: 27px;

    &:before {
      height: 3px;
      width: 56px;
      bottom: 6px;
    }

    &.-transparent-background {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}
