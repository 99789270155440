.search {
  width: 100%;
  margin: $spacing4 0 $spacing6;

  @include screen-md {
    margin-bottom: $spacing3;
  }

  &--submit {
    @include font-body;
    margin: 0;
    margin-left: $spacing-md;
    flex-shrink: 0;
    height: unset;

    @include screen-sm-max {
      display: none !important;
    }
  }

  &--clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $text-color;
    padding: $spacing4;
    cursor: pointer;
  }

  &--input-wrapper {
    @include screen-sm {
      display: flex;
    }
  }

  &--field {
    width: 100%;
    position: relative;
  }

  &--input {
    @include font-size-xl;
    border-radius: 45px;
    color: $text-color;

    input {
      padding: $spacing3 $spacing2 $spacing3 $spacing7;
      caret-color: $primary-blue;
    }
  }

  &--results {
    color: $text-color;
    margin-top: $spacing-lg;

    @include screen-md {
      margin-left: $spacing-lg;
    }

    a {
      color: $text-color;
      text-decoration: underline;
    }
  }

  &--results-icon {
    width: 8px;
    height: 8px;
    color: $focus-color;
    margin-left: 2px !important;
  }
}
