.navigation-group {
  margin-top: $spacing-xxl;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2) {
    @include screen-lg {
      margin-top: $spacing4;
    }
  }

  h2 {
    @include font-data;
  }

  > ul {
    @include remove-list-styles;
    @include font-headline3;
    margin: $spacing3 0 0;

    > li {
      padding: $spacing3 0;
      border-bottom: 1px solid $border-grey;

      .link {
        cursor: pointer;
      }

      a.-has-icon-right {
        display: flex;
        justify-content: space-between;
      }

      a.-is-external-link {
        margin-right: 0 !important;

        & > span {
          width: 100%;
        }

        .external-link-icon-skinny {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
