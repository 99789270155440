.message-global-list--print {
  display: none;

  @media print {
    display: block;
    bottom: 0;
    width: 100%;

    &-message {
      display: flex;
      margin-bottom: 1rem;
    }

    &-line {
      background: $text-color;
      height: 1px;
      width: 100%;
    }

    &-description {
      margin: 1rem 0;
      font-family: Courier;
      font-size: convert-to-rem(14px);
    }
  }
}
