.dates-more-link-list {
  background: white;
  list-style-type: none;
  padding: 0 0 $spacing-xs 0;
  margin: 0;
  font-size: convert-to-rem(16px);
  border: 1px solid $primary-grey3;
  border-top: none;
  border-radius: 0 0 10px 10px;
  position: absolute;
  width: 100%;
}
