.education-block {
  display: flex;
  flex-direction: column-reverse;
  background-color: $np-alfven;

  @include screen-md {
    min-height: 440px;
    flex-direction: row;

    &.-reverse-order {
      flex-direction: row-reverse;
    }
  }

  &.-theme-yellow {
    background-color: $np-esaki;
  }

  &.-theme-blue {
    background-color: $np-marconi;
  }

  &--image {
    padding-top: (9 / 16) * 100%;
    position: relative;
    width: 100%;

    @include screen-md {
      padding-top: 0;
      width: 50%;
    }

    @media print {
      padding-top: 0;
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    padding: $spacing-lg;
    flex: 1 0 0%;

    @include screen-sm {
      padding: $spacing-xl;
    }
  }

  &--info {
    display: flex;
    position: relative;
    margin-bottom: $spacing-xl;

    span {
      &:nth-child(1) {
        margin-right: $spacing-md;
      }

      &:nth-child(2) {
        margin-left: $spacing-md;
      }
    }
  }

  &--title {
    @include font-size-xxl;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.4;
  }

  &--ingress {
    margin-top: 0;
  }

  &--time {
    display: inline-flex;
    align-items: center;
  }

  &--clock {
    height: 16px;
    width: 16px;
    margin-right: $spacing-sm;
  }

  &--link {
    margin-top: auto;
    align-self: flex-start;
  }
}