.timeline-vertical {
  margin-top: $spacing-lg;
  padding: $spacing5;
  background: $background-light-blue;

  @include screen-md {
    padding: $spacing-xl;
  }

  &--title {
    margin-top: 0;
  }

  &--record {
    display: flex;

    &-date {
      @include font-data-large;
      width: 40px;
      margin-top: 7px;

      @include screen-sm {
        width: 110px;
      }
    }

    &-text {
      @include font-body;
      margin-top: 2px;
      flex: 1;
      margin-bottom: $spacing-sm;
    }

    &-timeline {
      position: relative;
      width: 50px;

      @include screen-md {
        width: 100px;
      }

      &-line {
        margin: auto;
        width: 2px;
        height: 100%;
        transform: translateY(8px);
        background: $primary-grey3;

        &.-fade-bottom {
          background: linear-gradient(360deg,
              rgba(0, 0, 0, 0) 0%,
              $primary-grey3 40%);
          transition: background 0.2s 0.4s;
        }

        &.-hide {
          display: none;
        }
      }

      &-marker {
        @include timeline-marker;
        top: 0;
        left: 50%;
        transform: translate(-50%, 6px);
        border: 4px solid $primary-grey3;
        background-color: white;
      }
    }
  }

  &--expand-button {
    display: block;
    margin: 1rem auto 0;
    padding: 1rem 1rem 0;
  }

  &--icon {
    width: 24px;
    height: 15px;

    &.-is-open {
      transform: rotateZ(180deg);
    }
  }
}