.spinner {
  position: absolute;
  left: 50%;
  top: 10rem;
  transform: translateX(-50%);

  &-background {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0%;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }

  &-border-background {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid $primary-light-blue;
    border-radius: 100%;
    z-index: -1;
  }

  &--wrapper {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    &.active {
      animation: container-rotate 1568ms linear infinite;
    }
  }
}
.preload-wrapper {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  &.active {
    animation: container-rotate 1568ms linear infinite;
  }
}
@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: black;
}

.spinner-red,
.spinner-red-only {
  border-color: $primary-blue;
}

.active .spinner-layer.spinner-red {
  /* durations: 4 * ARCTIME */
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  /* durations: 4 * ARCTIME */
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  } /* 0.5 * ARCSIZE */
  25% {
    transform: rotate(270deg);
  } /* 1   * ARCSIZE */
  37.5% {
    transform: rotate(405deg);
  } /* 1.5 * ARCSIZE */
  50% {
    transform: rotate(540deg);
  } /* 2   * ARCSIZE */
  62.5% {
    transform: rotate(675deg);
  } /* 2.5 * ARCSIZE */
  75% {
    transform: rotate(810deg);
  } /* 3   * ARCSIZE */
  87.5% {
    transform: rotate(945deg);
  } /* 3.5 * ARCSIZE */
  to {
    transform: rotate(1080deg);
  } /* 4   * ARCSIZE */
}

@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;

  .circle {
    width: 200%;
    height: 100%;
    border-width: 6px; /* STROKEWIDTH */
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.left .circle {
    left: 0;
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
  }
  &.right .circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    transform: rotate(-129deg);
  }
}

.active .circle-clipper.left .circle {
  /* duration: ARCTIME */
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
  /* duration: ARCTIME */
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
