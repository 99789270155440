.link-list {
  @include remove-list-styles;
  margin: 0;

  &.-theme-frontpage-links {
    background: $primary-blue;

    @include screen-md {
      display: flex;
    }

    li {
      padding: $spacing3 0;
      margin-left: $spacing5;

      @include screen-md {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing4 0;
        margin-left: $spacing7;
      }

      .link {
        color: white;
      }
    }
  }

  &.-theme-portfolio {
    padding-top: 0;
    margin-bottom: $spacing4;

    .link-list--link {
      padding: $spacing3 0 12px;
      border-bottom: 1px solid $divider-grey2;

      &.-theme-large {
        padding: $spacing4 0 18px;
      }
    }
  }

  li {
    padding: $spacing-sm 0;

    .link {
      @media print {
        font-size: 18px;
      }
    }
  }

  &.-theme-border {
    li {
      border-bottom: 1px solid rgba($element-color--darker, 0.3);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &--with-icon {
    .link {
      display: inline-flex !important;
    }
  }
}