.contact-info {
  h3 {
    @include font-size-body;
    font-weight: bold;
    margin: 0;
  }

  ul {
    @include remove-list-styles;
    @include font-size-sm;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    flex-direction: column;

    li::after {
      display: none;
    }

  }

  .link-details {
    margin-top: 1em;
  }

  li {
    @include line-separator;
    margin-right: $spacing-sm;
    padding-right: $spacing-sm;
  }
}