.date-card-media {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $spacing-md;
  padding-bottom: $spacing-sm;

  @include screen-md {
    align-items: flex-end;
    margin-top: 0;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-top: $spacing-xs;

    &:link,
    &:visited {
      color: $link-color !important;
    }

    &:link:hover {
      color: $text-color !important;

      .date-card-media-text {
        text-decoration: underline;
      }
    }

    &.-theme-black {
      color: $text-color;
    }

    @include screen-md {
      width: 100%;

      + .date-card-media-item {
        margin-top: $spacing-lg;
      }
    }
  }

  &-icon {
    color: inherit;

    @include screen-md {
      margin-right: 0;
    }
  }

  &-text {
    margin-left: 0.5rem;
    color: inherit;
  }
}
