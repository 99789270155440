.search-page {
  &--title {
    @include list-page-title;
  }

  &--results {
    @include font-size-xs;
    color: $text-color;
    margin-top: $spacing-md;

    @include screen-sm {
      margin-top: $spacing-xxl;
    }
  }
}
