.nested-menu {
  height: 57px; // height of closed menu

  @include screen-lg {
    margin-top: $spacing-xxl;
    height: auto;

  }

  &--inner {
    left: 0;
    background: $blue-light;
    padding: $spacing-md;

    @include screen-lg-max {
      position: absolute;
      width: 70%;
    }
  }

  &--title {
    @include fluid-type(18px, 24px);
    margin: 0 $spacing-md 0 auto;
    font-size: 1rem;

    @include screen-lg-max {
      text-transform: uppercase;
    }
  }

  &--button {
    display: flex;
    align-items: center;
    width: 100%;
    color: $link-color;

    &-icon {
      width: 20px;
      margin-right: auto;

      &.-close {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &--main-page-link {
    display: block;
    margin-top: $spacing-md;
    color: $purple !important;
  }

  &--teacher-link {
    display: block;
    margin-top: $spacing-sm;
    color: $purple !important;
  }

  &--list {
    padding: 0;
    margin-top: 0;

    &-item {
      list-style: none;

      &-button,
      &-icon,
      .link {
        color: $link-color;
      }

      &-icon {
        color: $primary-blue;

        transform: rotateZ(-90deg);
        width: 13px;

        &.-is-open {
          transform: rotateZ(0deg);
        }
      }

      &.-current-section {
        .link {
          color: $text-color;
        }
      }

      &-button {
        display: flex;
        align-items: baseline;
        height: auto;
      }

      &-title {
        font-size: 1rem;
        margin-left: 12px;
        margin-bottom: $spacing-md;
        margin-top: $spacing-md;
        line-height: 145%;
        text-align: left;
        flex: 1;
      }
    }
  }

  &--inner-list {
    margin-bottom: $spacing-sm;

    &-item {
      list-style: none;
      line-height: 145%;
      margin-bottom: $spacing-sm;

      &.-single-link {
        margin-bottom: 0;
        margin-top: $spacing-md;
      }

      &:hover .link,
      &.-current-page .link {
        border-bottom: 1px solid $link-color;
      }
    }
  }
}