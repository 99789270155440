// Heading
@mixin font-display1($important: false) {
  @include fluid-type(36px, 76px, $important: $important);
  font-weight: 500;
  line-height: 1;
}

@mixin font-display2($important: false) {
  @include fluid-type(36px, 64px, $important: $important);
  font-weight: 500;
  line-height: 1;
}

@mixin font-headline1($important: false) {
  @include fluid-type(32px, 48px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-headline2($important: false) {
  @include fluid-type(28px, 32px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-headline3($important: false) {
  @include fluid-type(20px, 24px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

// Paragraphs
@mixin font-lead($important: false) {
  @include fluid-type(20px, 24px, $important: $important);
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-large-headline($important: false) {
  @include fluid-type(18px, 20px, $important: $important);
  font-weight: 600;
  line-height: 1.25;
}

@mixin font-large-subtitle($important: false) {
  @include fluid-type(18px, 20px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-large($important: false) {
  @include fluid-type(18px, 20px, $important: $important);
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-body-headline($important: false) {
  @include fluid-type(16px, 18px, $important: $important);
  font-weight: 600;
  line-height: 1.25;
}

@mixin font-body-subtitle($important: false) {
  @include fluid-type(16px, 18px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-body($important: false) {
  @include fluid-type(16px, 18px, $important: $important);
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-small-headline($important: false) {
  @include fluid-type(14px, 16px, $important: $important);
  font-weight: 600;
  line-height: 1.25;
}

@mixin font-small-subtitle($important: false) {
  @include fluid-type(14px, 16px, $important: $important);
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-small($important: false) {
  @include fluid-type(14px, 16px, $important: $important);
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-tiny($important: false) {
  @include fluid-type(14px, 14px, $important: $important);
  font-weight: 400;
  line-height: 1.5;
}

// Captions
@mixin font-caption-headline($important: false) {
  @include fluid-type(12px, 12px, $important: $important);
  font-weight: 700;
  line-height: 1.45;
}

@mixin font-caption($important: false) {
  @include fluid-type(12px, 12px, $important: $important);
  font-weight: 400;
  line-height: 1.45;
}

// Data
@mixin font-data-large($important: false) {
  @include fluid-type(14px, 14px, $important: $important);
  font-family: IBM;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

@mixin font-data($important: false) {
  @include fluid-type(12px, 12px, $important: $important);
  font-family: IBM;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

@mixin fluid-type(
  $min-font-size,
  $max-font-size,
  $min-vw: $breakpoint-md,
  $max-vw: $breakpoint-lg,
  $default: true,
  $important: false
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  $min-font-size: convert-to-rem($min-font-size);
  $max-font-size: convert-to-rem($max-font-size);

  @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
    & {
      @if $default ==true {
        @if $important {
          font-size: $min-font-size !important;
        } @else {
          font-size: $min-font-size;
        }
      }

      @media screen and (min-width: $min-vw) {
        $intermediateValue: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            (
              (100vw - #{convert-to-rem($min-vw)}) / #{strip-unit(
                  convert-to-rem($max-vw - $min-vw)
                )}
            )
        );

        @if $important {
          font-size: $intermediateValue !important;
        } @else {
          font-size: $intermediateValue;
        }
      }

      @media screen and (min-width: $max-vw) {
        @if $important {
          font-size: $max-font-size !important;
        } @else {
          font-size: $max-font-size;
        }
      }
    }
  } @else {
    @error "Detected mixed units. Please use the same units for all parameters.";
  }
}

//
//
// OLD STYLE
@mixin font-size-xs {
  font-size: convert-to-rem(14px);
}

@mixin font-size-sm($important: false) {
  @include fluid-type(14px, 16px);
}

@mixin font-size-body($important: false) {
  @include fluid-type(16px, 18px);
}

@mixin font-size-lg($important: false) {
  @include fluid-type(18px, 20px);
}

@mixin font-size-xl($important: false) {
  @include fluid-type(21px, 24px);
  line-height: 1.1;
}

@mixin font-size-xxl($important: false) {
  @include fluid-type(24px, 32px);
  line-height: 1.1;
}

@mixin font-size-section-heading($important: false) {
  @include fluid-type(26px, 34px);
  line-height: 1.1;
}

@mixin font-size-heading($important: false) {
  @include fluid-type(30px, 40px);
  line-height: 1.1;
}

@mixin font-size-huge($important: false) {
  @include fluid-type(70px, 100px);
  @include fluid-type(70px, 100px, $breakpoint-md, $breakpoint-xl, false);
  line-height: 0.7;
}

@mixin font-size-portfolio($important: false) {
  @include fluid-type(16px, 18px);
  font-weight: bold;
}

// Inputs need to be 16px on iPhone, or else the page will be zoomed in on the input when focused
@mixin font-size-input {
  font-size: convert-to-rem(16px);
  @include fluid-type(14px, 16px, $breakpoint-md, $breakpoint-lg, false);
}
