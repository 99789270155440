.message-global {
  * {
    color: black !important; // For WCAG approved color contrast
  }

  &--container {
    height: fit-content;

    background-color: $info-yellow;
    transition: height $transition;

    &.-theme-red {
      background-color: $info-red;
    }

    &.-theme-blue {
      background-color: $info-blue;
      color: black;
    }

    &.-is-open {
      height: auto;
    }
    &.-fade-out {
      animation: fadeOut $transition;
      visibility: hidden;
    }
    &.-fade-in-next {
      animation: fadeInNext $transition;
      opacity: 1;
      visibility: visible;
    }
    &.-fade-in-previous {
      animation: fadeInPrevious $transition;
      opacity: 1;
      visibility: visible;
    }
    &.-fade-in-previous-slow {
      animation: fadeInPreviousSlow 0.3s ease-out;
      opacity: 1;
      visibility: visible;
    }
  }

  $self: &;
  display: flex;
  padding: $spacing3 0;
  transition: padding $transition;
  flex-direction: column;
  height: 100%;

  @keyframes fadeInPreviousSlow {
    0% {
      opacity: 0;
      transform: translateX(-8%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeInPrevious {
    0% {
      opacity: 0;
      transform: translateX(-15%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInNext {
    0% {
      opacity: 0;
      transform: translateX(15%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeOut {
    0% {
      visibility: visible;
    }
    99% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }

  @media print {
    display: none;
  }

  &--title {
    @include font-small-headline;
    margin: 3px 0 4px;
    padding-right: $spacing3;

    @at-root #{$self}--container:not(.-is-open) .-multi-lined {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &--open-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: unset;
    padding: 0;
    text-align: left;
    cursor: pointer;

    &-title {
      display: flex;
      align-items: flex-start;
    }

    &-label {
      display: flex;
      align-items: center;
      padding-top: 3px;
    }
  }

  &--collapse {
    padding: 0 $spacing8 0 $spacing7;
    margin-top: -5px;
    @include font-small;
    flex: 1;

    &.-use-intrinsic-height {
      flex: none;
    }

    & p {
      @include fluid-type(14px, 16px, $important: true);
    }

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &--navigation {
    display: flex;
    align-items: center;
    padding-top: $spacing3;
    margin-top: auto;

    & *:not(:last-child) {
      margin-right: $spacing1;
    }

    & > button {
      @include remove-button-styles;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:nth-of-type(2) {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &-of {
      text-transform: uppercase;
    }
  }

  &--icon-warning {
    width: $spacing4;
    height: $spacing4;
    margin-right: 19px;
  }

  &--icon-accordion {
    @include accordion-icon;
    margin-left: $spacing2;
  }
}
