.byline {
  @include font-size-xs;
  @include cf;
  color: $text-color;

  &--item {
    @include screen-sm {
      float: left;

      &:not(:last-child) {
        @include line-separator;

        margin-right: 10px;
        padding-right: 10px;
      }
    }
  }

  &.-theme-border {
    border: 1px $element-color--lighter;
    border-style: solid none;
    padding: $spacing-xs 0;
  }
}
